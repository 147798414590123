import React from 'react';
import PropTypes from 'prop-types';
import carouselIcon from './svgIcons/carousel';
import dashboardIcon from './svgIcons/dashboard';
import buttonIcon from './svgIcons/button';
import mediaIcon from './svgIcons/media';
import microsoftIcon from './svgIcons/microsoft';
import progressBarIcon from './svgIcons/progressBar';
import santaRunIcon from './svgIcons/santaRun';
import santaStopIcon from './svgIcons/santaStop';
import timelineIcon from './svgIcons/timeline';
import xmarkIcon from './svgIcons/xmark';

const svgPathIcons = {
  button: buttonIcon,
  carousel: carouselIcon,
  dashboard: dashboardIcon,
  microsoft: microsoftIcon,
  media: mediaIcon,
  progressBar: progressBarIcon,
  santaRun: santaRunIcon,
  santaStop: santaStopIcon,
  timeline: timelineIcon,
  xmark: xmarkIcon,
};

const SvgIcon = ({
  icon,
  size,
  color,
  height,
  width,
}) => {
  const PathIcon = svgPathIcons[icon];
  return <PathIcon size={size} color={color} height={height} width={width} />;
};

SvgIcon.propTypes = {
  icon: PropTypes.string.isRequired,
  color: PropTypes.string,
  size: PropTypes.string,
  height: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  width: PropTypes.number,
};

export default SvgIcon;
