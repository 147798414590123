import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import HttpStatus from 'http-status-codes';
import omit from 'lodash.omit';
import { ColorPicker } from '@intelligenceindustrielle/react-ui-components';
import {
  DefaultThemedButton, DeleteButton, CancelButton, SubmitButton, FontAwesome, IconPicker,
} from '~UI';
import { reduxOperations } from '~services';
import { StopCauseIcon } from '~UI/RoundButton/RoundIcons';
import { getFormData } from '~utils';
import { showError, showSuccess } from '~utils/toast';
import { getCauseInfosByNameOrId } from '../utils';
import MultipleMachinesPopup from '../MultipleMachinesPopup';
import '../stopCauses/StopCausesForm.scss';

const ActionType = {
  EDIT: 'edit',
  EXPORT: 'export',
};

const DefectCauseForm = ({
  firstDefectCause, goBackEdition, goBackForm, isInSubMenu, machineId, defectCause,
}) => {
  const dispatch = useDispatch();

  const { t } = useTranslation();
  const machines = useSelector(state => state.machines);
  const images = useSelector(state => state.images.images);

  const [name, setName] = useState(defectCause.name || '');
  const [icon, setIcon] = useState(defectCause.icon || '');
  const [color, setColor] = useState(defectCause.color || '#CCC');
  const [showPopup, setShowPopup] = useState(false);
  const [showMachineList, setShowMachineList] = useState(false);
  const [machinesSelected, setMachinesSelected] = useState([]);
  const [operation, setOperation] = useState(null);
  const [actionType, setActionType] = useState(ActionType.EDIT);

  const verifyFormData = () => {
    if (!name.length || !icon.length) {
      showError(t('invalidFormData'));
      return false;
    }
    return true;
  };

  useEffect(() => {
    const { name: newName, icon: newIcon, color: newColor } = defectCause;

    setName(newName || '');
    setIcon(newIcon || '');
    setColor(newColor || '#CCC');
  }, [defectCause]);

  const addDefectCause = (e, selected = []) => {
    if (e) {
      e.preventDefault();
    }

    const formData = getFormData('form_defectCause');
    if (!verifyFormData()) {
      setOperation(null);
      return;
    }
    for (const mId of [...selected, machineId]) {
      if (isInSubMenu) {
        const machine = machines.find(m => m.id === mId);
        const parentDefectCause = selected.length > 0 ? getCauseInfosByNameOrId(
          null, firstDefectCause, machine.defectCauses, true,
        )?.childCause : getCauseInfosByNameOrId(null, firstDefectCause, machine.defectCauses)?.childCause;
        dispatch(reduxOperations.machines.addSubDefectCause(mId, parentDefectCause?.id, formData))
          .then(() => {
            showSuccess(t('showSuccessCreated'));
          })
          .catch(error => {
            if (error.code === HttpStatus.CONFLICT) {
              showError(t('conflictError'));
            }
          });
        goBackEdition();
      } else {
        dispatch(reduxOperations.machines.addDefectCause(mId, formData))
          .then(() => {
            showSuccess(t('showSuccessCreated'));
            goBackForm();
          })
          .catch(error => {
            if (error.code === HttpStatus.CONFLICT) {
              showError(t('conflictError'));
            }
          });
      }
    }
    setOperation(null);
  };

  const removeDefectCause = (e, selected = []) => {
    if (e) {
      e.preventDefault();
    }
    for (const mId of [...selected, machineId]) {
      const machine = machines.find(m => m.id === mId);
      if (isInSubMenu) {
        const { parentCause, childCause } = selected.length > 0 ? getCauseInfosByNameOrId(
          firstDefectCause, defectCause, machine.defectCauses, true,
        ) : getCauseInfosByNameOrId(firstDefectCause, defectCause, machine.defectCauses);
        parentCause.subMenu = parentCause?.subMenu.filter(subOption => subOption.id !== childCause?.id);

        dispatch(reduxOperations.machines.updateDefectCause(mId, parentCause?.id, parentCause))
          .then(() => {
            showSuccess(t('showSuccessUpdated'));
          })
          .catch(error => {
            if (error.code === HttpStatus.CONFLICT) {
              showError(t('conflictError'));
            }
          });
        goBackEdition();
      } else {
        const deletedDefectCause = selected.length > 0 ? getCauseInfosByNameOrId(
          firstDefectCause, defectCause, machine.defectCauses, true,
        )?.childCause : getCauseInfosByNameOrId(firstDefectCause, defectCause, machine.defectCauses)?.childCause;
        dispatch(reduxOperations.machines.deleteDefectCause(mId, deletedDefectCause?.id))
          .then(() => {
            showSuccess(t('showSuccessDeleted'));
          });
      }
    }
    goBackForm();
  };

  const modifyDefectCause = (e, selected = []) => {
    if (e) {
      e.preventDefault();
    }
    const formData = getFormData('form_defectCause');
    if (!verifyFormData()) {
      setOperation(null);
      return;
    }
    for (const mId of [...selected, machineId]) {
      const machine = machines.find(m => m.id === mId);
      if (isInSubMenu) {
        const {
          parentCause,
          childCause,
        } = selected.length > 0 ? getCauseInfosByNameOrId(
          firstDefectCause, defectCause, machine.defectCauses, true,
        ) : getCauseInfosByNameOrId(firstDefectCause, defectCause, machine.defectCauses);
        dispatch(reduxOperations.machines.updateSubDefectCause(
          mId, parentCause?.id, childCause?.id, { ...childCause, ...formData },
        ))
          .then(() => {
            showSuccess(t('showSuccessUpdated'));
          })
          .catch(error => {
            if (error.code === HttpStatus.CONFLICT) {
              showError(t('conflictError'));
            }
          });
      } else {
        const updatedDefectCause = selected.length > 0 ? getCauseInfosByNameOrId(
          firstDefectCause, defectCause, machine.defectCauses, true,
        )?.childCause : getCauseInfosByNameOrId(firstDefectCause, defectCause, machine.defectCauses)?.childCause;
        const newDefectCause = { ...updatedDefectCause, ...formData };

        dispatch(reduxOperations.machines.updateDefectCause(mId, updatedDefectCause?.id, newDefectCause))
          .then(() => {
            showSuccess(t('showSuccessUpdated'));
          })
          .catch(error => {
            if (error.code === HttpStatus.CONFLICT) {
              showError(t('conflictError'));
            }
          });
      }
    }
    goBackEdition();
    setOperation(null);
  };

  const exportDefectCause = (e, selected = []) => {
    if (e) {
      e.preventDefault();
    }
    if (!selected.length) {
      showError(t('noMachineToExportTo'));
      setOperation(null);
      setActionType(ActionType.EDIT);
      return;
    }
    for (const mId of selected) {
      const machine = machines.find(m => m.id === mId);
      if (isInSubMenu) {
        const parentCause = selected.length > 0 ? getCauseInfosByNameOrId(
          null, firstDefectCause, machine.defectCauses, true,
        )?.childCause : getCauseInfosByNameOrId(null, firstDefectCause, machine.defectCauses)?.childCause;
        const defectCauseClone = omit(defectCause, ['id']);
        dispatch(reduxOperations.machines.addSubDefectCause(
          mId,
          parentCause?.id,
          defectCauseClone,
        ))
          .then(() => {
            showSuccess(t('showSuccessCreated'));
          })
          .catch(error => {
            if (error.code === HttpStatus.CONFLICT) {
              showError(t('conflictError'));
            }
          });
      } else {
        const defectCauseClone = omit(defectCause, ['_id', 'id']);
        const subMenuClone = defectCauseClone.subMenu.forEach(subOption => omit(subOption, ['id']));
        dispatch(reduxOperations.machines.addDefectCause(mId, { ...defectCauseClone, subMenu: subMenuClone }))
          .then(() => {
            showSuccess(t('showSuccessCreated'));
          })
          .catch(error => {
            if (error.code === HttpStatus.CONFLICT) {
              showError(t('conflictError'));
            }
          });
      }
    }
    goBackEdition();
    setOperation(null);
    setActionType(ActionType.EDIT);
  };

  const openMultipleMachinePopup = (e, operationArg) => {
    if (e) {
      e.preventDefault();
    }
    setOperation(() => operationArg);
    setShowPopup(true);
  };

  const cancelPopup = () => {
    setShowPopup(false);
    setShowMachineList(false);
    setActionType(ActionType.EDIT);
  };

  const selectMachine = machineIdArg => {
    if (Array.isArray(machineIdArg) && machineIdArg.length === 0) {
      setMachinesSelected([]);
      return;
    }
    const ids = Array.isArray(machineIdArg) ? machineIdArg : [machineIdArg];
    ids.forEach(id => {
      const index = machinesSelected.indexOf(id);
      if (index === -1) {
        setMachinesSelected(prev => [...prev, id]);
      } else {
        setMachinesSelected(prev => prev.filter(mId => id !== mId));
      }
    });
  };

  const renderMultipleMachinesPopup = () => {
    const validMachines = machines.filter(machine => {
      if (machine.id === machineId || name === '' || icon === '') {
        return false;
      }
      const defectCauseInfos = defectCause.name
        ? getCauseInfosByNameOrId(firstDefectCause, defectCause, machine.defectCauses, true)
        : undefined;
      const defectCauseParentInfos = firstDefectCause
        ? getCauseInfosByNameOrId(null, firstDefectCause, machine.defectCauses, true)
        : undefined;
      switch (actionType) {
        case ActionType.EDIT:
          // If is editing or deleting
          if (defectCause.name) {
            // If a stop cause has the same name and both are in a submenu with the same parent name
            const hasSub = defectCauseInfos && defectCauseInfos.parentCause && isInSubMenu
            && firstDefectCause.name === defectCauseInfos.parentCause.name;
            if (hasSub) {
              return true;
            }
            // If a stop cause has the same name and is NOT in a submenu and are both root stop causes
            const hasParent = defectCauseInfos && !isInSubMenu && !defectCauseInfos.parentCause;
            if (hasParent) {
              return true;
            }
          } else { // If is adding a stop cause
            if (!isInSubMenu) {
              return true;
            }
            // If a stop cause with the same name than the parent exists and that it is a parent itself
            const hasParent = defectCauseParentInfos && !defectCauseParentInfos.childCause.subMenu
              .find(subOption => subOption.name === name);
            if (hasParent) {
              return true;
            }
          }
          return false;
        case ActionType.EXPORT:
          // If is root stop cause
          if (!isInSubMenu) {
            // If a stop cause with the same name does not exist
            if (!defectCauseInfos) {
              return true;
            }
          // If a root stop cause with the same name exists but sub stop cause with the same name does not exist
          } else {
            const hasParentButNotSub = defectCauseParentInfos && !defectCauseParentInfos.childCause.subMenu
              .find(subOption => subOption.name === defectCause.name);
            if (hasParentButNotSub) {
              return true;
            }
          }
          return false;
        default:
          return false;
      }
    });

    if (!validMachines.length && operation) {
      operation(null, []);
      setShowPopup(false);
      return;
    }

    return (
      <MultipleMachinesPopup
        showPopup={showPopup}
        showMachineList={showMachineList}
        operation={operation}
        validMachines={validMachines}
        machinesSelected={machinesSelected}
        cancelPopup={cancelPopup}
        setShowMachineList={value => setShowMachineList(value)}
        selectMachine={id => selectMachine(id)}
      />
    );
  };

  return (
    <form
      id="form_defectCause"
      onSubmit={defectCause.name
        ? e => openMultipleMachinePopup(e, modifyDefectCause)
        : e => openMultipleMachinePopup(e, addDefectCause)
      }
    >
      {showPopup && renderMultipleMachinesPopup()}
      <FontAwesome
        icon="arrow-left"
        className="backButtonArrow"
        style={{ marginLeft: '0px', cursor: 'pointer', marginTop: '4px', fontSize: '20px' }}
        onClick={goBackEdition || goBackForm}
      />
      {defectCause.name && (
        <DefaultThemedButton
          key="btnExport"
          content={t('export')}
          isActive={false}
          className="exportButton"
          onClick={e => {
            setActionType(ActionType.EXPORT);
            setShowMachineList(true);
            openMultipleMachinePopup(e, exportDefectCause);
          }}
          style={{ float: 'right' }}
        />
      )}
      <table className="DefectCauseForm fullwidth">
        <tbody>
          <tr>
            <td className="halfwidth">
              <div className="inputTitle">{t('name')}</div>
              <input
                type="text"
                className="fullwidth"
                name="name"
                value={name}
                onChange={e => setName(e.target.value)}
              />
              <div className="inputTitle">{t('color')}</div>
              <ColorPicker
                name="color"
                onChange={hex => setColor(hex)}
                value={color}
              />
            </td>
            <td className="halfwidth">
              <div className="centered">
                <StopCauseIcon
                  option={{ name, icon, color }}
                />
              </div>
            </td>
          </tr>
          <tr>
            <td colSpan={2}>
              <IconPicker
                onClick={setIcon}
                defaultIcon={icon}
                addImage={image => dispatch(reduxOperations.images.addImage(image))}
                images={images}
              />
              <div className="buttonsHolder flexSpaceBetween">
                {
                  defectCause.name ? (
                    <DeleteButton
                      handleDelete={() => openMultipleMachinePopup(null, removeDefectCause)}
                      askConfirmation
                    />
                  ) : <div />
                }
                <div>
                  <CancelButton onClick={goBackEdition || goBackForm} />
                  <SubmitButton
                    label={defectCause.name ? t('edit') : t('add')}
                  />
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </form>
  );
};

DefectCauseForm.propTypes = {
  firstDefectCause: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  }),
  goBackEdition: PropTypes.func,
  goBackForm: PropTypes.func.isRequired,
  isInSubMenu: PropTypes.bool.isRequired,
  defectCause: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    icon: PropTypes.string,
    color: PropTypes.string,
  }),
  machineId: PropTypes.string.isRequired,
};
DefectCauseForm.defaultProps = {
  firstDefectCause: null,
  goBackEdition: null,
  defectCause: {},
};

export default DefectCauseForm;
