import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Input, Select } from '@intelligenceindustrielle/react-ui-components';
import { RootState } from '~services/store';
import { sortArray } from '~utils/sort';
import { triggerTypes } from '~utils/types';
import './ActionForm.scss';

const PushNotificationActionForm = ({ action: actionProp }) => {
  const { t } = useTranslation();

  const users = useSelector((state: RootState) => state.users.users);
  const triggers = useSelector((state: RootState) => state.triggers);

  const [action] = useState(actionProp);

  const filteredTriggers = sortArray('alphabetically', triggers.filter(trigger => trigger.type === triggerTypes.DEFAULT), 'name');
  const userOptions = sortArray('alphabetically', users, 'name').map(u => ({ label: u.name, value: u.id }));

  return (
    <div className="pushNotificationActionForm">
      <div className="inputTitle">{t('selectUser')}</div>
      <Select
        name="id"
        options={userOptions}
        defaultValue={action.params.id}
        placeholder={t('select')}
      />

      <div className="inputTitle">{t('title')}</div>
      <Input
        defaultValue={action.params.title}
        name="title"
        placeholder="Notification Title"
      />
      <div className="inputTitle">{t('message')}</div>
      <Input
        defaultValue={action.params.message}
        name="message"
        placeholder="Notification Message"
      />
      <div className="inputTitle">{t('button')}</div>
      <Input
        defaultValue={action.params.button1Text}
        name="button1Text"
        placeholder="Text of the accept button"
      />
      <Select
        style={{ width: '300px', display: 'inline-block' }}
        name="button1Action"
        placeholder={t('button')}
        defaultValue={action.params.button1Action}
        options={filteredTriggers.map(trigger => (
          { label: trigger.name, value: trigger.id }))}
      />
      <div className="inputTitle">{t('button')}</div>
      <Input
        defaultValue={action.params.button2Text}
        name="button2Text"
        placeholder="Text of the refuse button"
      />
      <Select
        style={{ width: '300px', display: 'inline-block' }}
        name="button2Action"
        placeholder={t('button')}
        defaultValue={action.params.button2Action}
        options={filteredTriggers.map(trigger => (
          { label: trigger.name, value: trigger.id }))}
      />
    </div>
  );
};

PushNotificationActionForm.propTypes = {
  action: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
      message: PropTypes.string,
      button1Text: PropTypes.string,
      button2Text: PropTypes.string,
      button1Action: PropTypes.string,
      button2Action: PropTypes.string,
    }).isRequired,
  }),
};
PushNotificationActionForm.defaultProps = {
  action: {
    params: {},
  },
};

export default PushNotificationActionForm;
