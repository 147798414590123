import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import HttpStatus from 'http-status-codes';
import omit from 'lodash.omit';
import cloneDeep from 'lodash.clonedeep';
import { ButtonGroup, ColorPicker } from '@intelligenceindustrielle/react-ui-components';
import {
  DefaultThemedButton, DeleteButton, CancelButton, SubmitButton, FontAwesome, IconPicker,
} from '~UI';
import { reduxOperations } from '~services';
import { StopCauseIcon } from '~UI/RoundButton/RoundIcons';
import { getFormData } from '~utils';
import { showError, showSuccess } from '~utils/toast';
import './StopCausesForm.scss';
import { getStopsTypes } from './utils';
import { getCauseInfosByNameOrId } from '../utils';
import MultipleMachinesPopup from '../MultipleMachinesPopup';

const ActionType = {
  EDIT: 'edit',
  EXPORT: 'export',
};

const StopCauseForm = ({
  firstStopCause, goBackEdition, goBackForm, isInSubMenu, stopcause, machineId,
}) => {
  const dispatch = useDispatch();

  const { t } = useTranslation();
  const machines = useSelector(state => state.machines);
  const stopCauseMachine = useSelector(state => state.machines.find(m => m.id === machineId));
  const images = useSelector(state => state.images.images);

  const [name, setName] = useState(stopcause.name || '');
  const [nameEN, setNameEN] = useState(stopcause.nameEN || '');
  const [nameFR, setNameFR] = useState(stopcause.nameFR || '');
  const [nameES, setNameES] = useState(stopcause.nameES || '');
  const [icon, setIcon] = useState(stopcause.icon || '');
  const [color, setColor] = useState(stopcause.color || '#CCC');
  const [type, setType] = useState(stopcause.type || 'failure');
  const [isUptime, setIsUptime] = useState(stopcause.isUptime || false);
  const [showPopup, setShowPopup] = useState(false);
  const [showMachineList, setShowMachineList] = useState(false);
  const [machinesSelected, setMachinesSelected] = useState([]);
  const [operation, setOperation] = useState(null);
  const [actionType, setActionType] = useState(ActionType.EDIT);
  const [language, setLanguage] = useState(stopCauseMachine.language);

  const verifyFormData = () => {
    if (!name.length || !icon.length) {
      showError(t('invalidFormData'));
      return false;
    }
    return true;
  };

  useEffect(() => {
    const {
      name: newName, nameEN: newNameEN, nameFR: newNameFR, nameES: newNameES,
      icon: newIcon, color: newColor, type: newType, isUptime: newIsUptime,
    } = stopcause;
    setName(newName || '');
    setNameEN(newNameEN || '');
    setNameFR(newNameFR || '');
    setNameES(newNameES || '');
    setIcon(newIcon || '');
    setColor(newColor || '#CCC');
    setType(newType || 'failure');
    setIsUptime(newIsUptime || false);
  }, [stopcause]);

  const addStopCause = (e, selected = []) => {
    if (e) {
      e.preventDefault();
    }
    const formData = getFormData('form_stopcause');
    formData.name = name;
    formData.nameEN = nameEN;
    formData.nameFR = nameFR;
    formData.nameES = nameES;
    if (!verifyFormData()) {
      setOperation(null);
      return;
    }
    for (const mId of [...selected, machineId]) {
      if (isInSubMenu) {
        const machine = machines.find(m => m.id === mId);
        const parentStopCause = selected.length > 0 ? getCauseInfosByNameOrId(
          null, firstStopCause, machine.stopCauses, true,
        )?.childCause
          : getCauseInfosByNameOrId(null, firstStopCause, machine.stopCauses)?.childCause;
        dispatch(reduxOperations.machines.addSubStopCause(
          mId,
          parentStopCause?.id,
          formData,
        ))
          .then(() => {
            showSuccess(t('showSuccessCreated'));
          })
          .catch(error => {
            if (error.code === HttpStatus.CONFLICT) {
              showError(t('conflictError'));
            }
          });
        goBackEdition();
      } else {
        dispatch(reduxOperations.machines.addStopCause(mId, formData))
          .then(() => {
            showSuccess(t('showSuccessCreated'));
            goBackForm();
          })
          .catch(error => {
            if (error.code === HttpStatus.CONFLICT) {
              showError(t('conflictError'));
            }
          });
      }
    }
    setOperation(null);
  };

  const removeStopCause = (e, selected = []) => {
    if (e) {
      e.preventDefault();
    }
    for (const mId of [...selected, machineId]) {
      const machine = machines.find(m => m.id === mId);
      if (isInSubMenu) {
        const {
          parentCause,
          childCause,
        } = selected.length > 0 ? getCauseInfosByNameOrId(
          firstStopCause, stopcause, machine.stopCauses, true,
        ) : getCauseInfosByNameOrId(firstStopCause, stopcause, machine.stopCauses);
        const newParentCause = cloneDeep(parentCause);
        newParentCause.subMenu = newParentCause?.subMenu?.filter(subOption => subOption.id !== childCause?.id) || [];
        dispatch(reduxOperations.machines.deleteSubStopCause(mId, parentCause?.id, childCause?.id))
          .then(() => {
            showSuccess(t('showSuccessDeleted'));
          })
          .catch(error => {
            if (error.response.error.includes('trigger')) {
              showError(t('stopCauseInUseTrigger'));
            } else {
              showError(t('stopCauseInUseAction'));
            }
          });
        goBackEdition();
      } else {
        const deletedStopCause = selected.length > 0 ? getCauseInfosByNameOrId(
          firstStopCause, stopcause, machine.stopCauses, true,
        )?.childCause : getCauseInfosByNameOrId(firstStopCause, stopcause, machine.stopCauses)?.childCause;
        dispatch(reduxOperations.machines.deleteStopCause(mId, deletedStopCause?.id))
          .then(() => {
            showSuccess(t('showSuccessDeleted'));
          })
          .catch(error => {
            if (error.response.error.includes('trigger')) {
              showError(t('stopCauseInUseTrigger'));
            } else {
              showError(t('stopCauseInUseAction'));
            }
          });
        goBackForm();
      }
    }
  };

  const modifyStopCause = (e, selected = []) => {
    if (e) {
      e.preventDefault();
    }
    const formData = getFormData('form_stopcause');
    formData.name = name;
    formData.nameEN = nameEN || (language === 'en' ? name : '');
    formData.nameFR = nameFR || (language === 'fr' ? name : '');
    formData.nameES = nameES || (language === 'es' ? name : '');
    if (!verifyFormData()) {
      setOperation(null);
      return;
    }
    for (const mId of [...selected, machineId]) {
      const machine = machines.find(m => m.id === mId);
      if (isInSubMenu) {
        const {
          parentCause,
          childCause,
        } = selected.length > 0 ? getCauseInfosByNameOrId(
          firstStopCause, stopcause, machine.stopCauses, true,
        ) : getCauseInfosByNameOrId(firstStopCause, stopcause, machine.stopCauses);
        dispatch(reduxOperations.machines.updateSubStopCause(
          mId, parentCause?.id, childCause?.id, { ...childCause, ...formData },
        ))
          .then(() => {
            showSuccess(t('showSuccessUpdated'));
          })
          .catch(error => {
            if (error.code === HttpStatus.CONFLICT) {
              showError(t('conflictError'));
            }
          });
      } else {
        const updatedStopCause = selected.length > 0 ? getCauseInfosByNameOrId(
          firstStopCause, stopcause, machine.stopCauses, true,
        )?.childCause : getCauseInfosByNameOrId(firstStopCause, stopcause, machine.stopCauses)?.childCause;
        const newStopCause = { ...updatedStopCause, ...formData };
        dispatch(reduxOperations.machines.updateStopCause(mId, updatedStopCause?.id, newStopCause))
          .then(() => {
            showSuccess(t('showSuccessUpdated'));
          })
          .catch(error => {
            if (error.code === HttpStatus.CONFLICT) {
              showError(t('conflictError'));
            }
          });
      }
    }
    goBackEdition();
    setOperation(null);
  };

  const exportStopCause = (e, selected = []) => {
    if (e) {
      e.preventDefault();
    }
    if (!selected.length) {
      showError(t('noMachineToExportTo'));
      setOperation(null);
      setActionType(ActionType.EDIT);
      return;
    }
    for (const mId of selected) {
      const machine = machines.find(m => m.id === mId);
      if (isInSubMenu) {
        const parentCause = selected.length > 0 ? getCauseInfosByNameOrId(
          null, firstStopCause, machine.stopCauses, true,
        )?.childCause : getCauseInfosByNameOrId(null, firstStopCause, machine.stopCauses)?.childCause;
        const stopCauseClone = omit(stopcause, ['id']);
        dispatch(reduxOperations.machines.addSubStopCause(
          mId,
          parentCause?.id,
          stopCauseClone,
        ))
          .then(() => {
            showSuccess(t('showSuccessCreated'));
          })
          .catch(error => {
            if (error.code === HttpStatus.CONFLICT) {
              showError(t('conflictError'));
            }
          });
      } else {
        const stopCauseClone = omit(stopcause, ['_id', 'id']);
        const subMenuClone = stopCauseClone.subMenu.map(subOption => omit(subOption, ['id']));
        dispatch(reduxOperations.machines.addStopCause(mId, { ...stopCauseClone, subMenu: subMenuClone }))
          .then(() => {
            showSuccess(t('showSuccessCreated'));
          })
          .catch(error => {
            if (error.code === HttpStatus.CONFLICT) {
              showError(t('conflictError'));
            }
          });
      }
    }
    goBackEdition();
    setOperation(null);
    setActionType(ActionType.EDIT);
  };

  const openMultipleMachinePopup = (e, operationArg) => {
    if (e) {
      e.preventDefault();
    }
    setOperation(() => operationArg);
    setShowPopup(true);
  };

  const cancelPopup = () => {
    setShowMachineList(false);
    setActionType(ActionType.EDIT);
    setShowPopup(false);
  };

  const selectMachine = machineIdArg => {
    if (Array.isArray(machineIdArg) && machineIdArg.length === 0) {
      setMachinesSelected([]);
      return;
    }
    const ids = Array.isArray(machineIdArg) ? machineIdArg : [machineIdArg];
    ids.forEach(id => {
      const index = machinesSelected.indexOf(id);
      if (index === -1) {
        setMachinesSelected(prev => [...prev, id]);
      } else {
        setMachinesSelected(prev => prev.filter(mId => id !== mId));
      }
    });
  };

  const renderMultipleMachinesPopup = () => {
    const validMachines = machines.filter(machine => {
      if (machine.id === machineId || name === '' || icon === '') {
        return false;
      }
      const stopCauseInfos = stopcause.name
        ? getCauseInfosByNameOrId(firstStopCause, stopcause, machine.stopCauses, true)
        : undefined;
      const stopCauseParentInfos = firstStopCause
        ? getCauseInfosByNameOrId(null, firstStopCause, machine.stopCauses, true)
        : undefined;
      switch (actionType) {
        case ActionType.EDIT:
          // If is editing or deleting
          if (stopcause.name) {
            // If a stop cause has the same name and both are in a submenu with the same parent name
            const hasSub = stopCauseInfos && stopCauseInfos.parentCause && isInSubMenu
              && firstStopCause.name === stopCauseInfos.parentCause.name;
            if (hasSub) {
              return true;
            }
            // If a stop cause has the same name and is NOT in a submenu and are both root stop causes
            const hasParent = stopCauseInfos && !isInSubMenu && !stopCauseInfos.parentCause;
            if (hasParent) {
              return true;
            }
          } else { // If is adding a stop cause
            if (!isInSubMenu) {
              return true;
            }
            // If a stop cause with the same name than the parent exists and that it is a parent itself
            const hasParent = stopCauseParentInfos && !stopCauseParentInfos.childCause.subMenu
              .find(subOption => subOption.name === name);
            if (hasParent) {
              return true;
            }
          }
          return false;
        case ActionType.EXPORT:
          // If is root stop cause
          if (!isInSubMenu) {
            // If a stop cause with the same name does not exist
            if (!stopCauseInfos) {
              return true;
            }
          // If a root stop cause with the same name exists but sub stop cause with the same name does not exist
          } else {
            const hasParentButNotSub = stopCauseParentInfos && !stopCauseParentInfos.childCause.subMenu
              .find(subOption => subOption.name === stopcause.name);
            if (hasParentButNotSub) {
              return true;
            }
          }
          return false;
        default:
          return false;
      }
    });

    if (!validMachines.length && operation) {
      operation(null, []);
      setShowPopup(false);
      return;
    }

    return (
      <MultipleMachinesPopup
        showPopup={showPopup}
        showMachineList={showMachineList}
        operation={operation}
        validMachines={validMachines}
        machinesSelected={machinesSelected}
        cancelPopup={cancelPopup}
        setShowMachineList={value => setShowMachineList(value)}
        selectMachine={id => selectMachine(id)}
      />
    );
  };

  const updateName = (value, lang) => {
    switch (lang) {
      case 'en':
        setNameEN(value);
        if (stopCauseMachine.language === 'en') {
          setName(value);
        }
        break;
      case 'fr':
        setNameFR(value);
        if (stopCauseMachine.language === 'fr') {
          setName(value);
        }
        break;
      case 'es':
        setNameES(value);
        if (stopCauseMachine.language === 'es') {
          setName(value);
        }
        break;
      default:
        break;
    }
  };

  const machineStatusButtons = [{
    label: t('uptime'),
    value: 'uptime',
  },
  {
    label: t('downtime'),
    value: 'downtime',
  }];

  const languageButtons = [{
    label: 'EN',
    value: 'en',
  },
  {
    label: 'FR',
    value: 'fr',
  },
  {
    label: 'ES',
    value: 'es',
  }];

  const languageNameMap = {
    en: nameEN,
    fr: nameFR,
    es: nameES,
  };

  const displayName = language === stopCauseMachine.language ? name : languageNameMap[language];

  return (
    <form
      id="form_stopcause"
      onSubmit={stopcause.name
        ? e => openMultipleMachinePopup(e, modifyStopCause)
        : e => openMultipleMachinePopup(e, addStopCause)
      }
    >
      {showPopup && renderMultipleMachinesPopup()}
      <FontAwesome
        icon="arrow-left"
        className="backButtonArrow"
        style={{ marginLeft: '0px', cursor: 'pointer', marginTop: '4px', fontSize: '20px' }}
        onClick={goBackEdition || goBackForm}
      />
      {stopcause.name && (
        <DefaultThemedButton
          key="btnExport"
          content={t('export')}
          isActive={false}
          className="exportButton"
          onClick={e => {
            setActionType(ActionType.EXPORT);
            setShowMachineList(true);
            openMultipleMachinePopup(e, exportStopCause);
          }}
          style={{ float: 'right' }}
        />
      )}
      <table className="StopCauseForm fullwidth">
        <tbody>
          <tr>
            <td className="halfwidth">
              <div className="inputTitle">{t('name')}</div>
              <div className="languageInput">
                <input
                  type="text"
                  className="three-quarters-width"
                  value={displayName}
                  onChange={e => updateName(e.target.value, language)}
                />
                <ButtonGroup
                  buttons={languageButtons}
                  onChange={setLanguage}
                  value={language}
                />
              </div>

              <div className="inputTitle">{t('type')}</div>
              <ButtonGroup
                buttons={getStopsTypes()}
                onChange={newType => setType(newType)}
                value={type}
              />
              <input type="hidden" readOnly name="type" value={type} />

              {
                (type !== 'planned') && (
                  <div>
                    {`${t('considerAs')}: `}
                    <ButtonGroup
                      buttons={machineStatusButtons}
                      onChange={val => setIsUptime(val === 'uptime')}
                      value={isUptime ? 'uptime' : 'downtime'}
                    />
                    <input type="hidden" readOnly name="boolean:isUptime" value={isUptime} />
                  </div>
                )
              }

              <div className="inputTitle">{t('color')}</div>
              <ColorPicker
                name="color"
                onChange={hex => setColor(hex)}
                value={color}
              />
            </td>
            <td className="halfwidth">
              <div className="centered">
                <StopCauseIcon
                  option={{ name, icon, color }}
                />
              </div>
            </td>
          </tr>
          <tr>
            <td colSpan={2}>
              <IconPicker
                onClick={setIcon}
                defaultIcon={icon}
                addImage={image => dispatch(reduxOperations.images.addImage(image))}
                images={images}
              />
              <div className="buttonsHolder flexSpaceBetween">
                {
                  stopcause.name ? (
                    <DeleteButton
                      handleDelete={() => openMultipleMachinePopup(null, removeStopCause)}
                      askConfirmation
                    />
                  ) : <div />
                }
                <div>
                  <CancelButton onClick={goBackEdition || goBackForm} />
                  <SubmitButton label={stopcause.name ? t('edit') : t('add')} />
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </form>
  );
};

StopCauseForm.propTypes = {
  firstStopCause: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  }),
  goBackEdition: PropTypes.func,
  goBackForm: PropTypes.func.isRequired,
  isInSubMenu: PropTypes.bool.isRequired,
  stopcause: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    nameEN: PropTypes.string,
    nameFR: PropTypes.string,
    nameES: PropTypes.string,
    icon: PropTypes.string,
    type: PropTypes.string,
    color: PropTypes.string,
    isUptime: PropTypes.bool,
  }),
  machineId: PropTypes.string.isRequired,
};
StopCauseForm.defaultProps = {
  firstStopCause: null,
  goBackEdition: null,
  stopcause: {},
};

export default StopCauseForm;
