import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Input, ButtonGroup } from '@intelligenceindustrielle/react-ui-components';
import { RootState } from '~services/store';
import RuleEditor from '~components/forms/Rule/RuleEditor';
import { FormStyle } from '~components/forms/FormStyle';
import { shouldDisplayFormContent } from '~components/forms/NavigationTabs';
import { reducersTypes, reduxOperations } from '~services/index';
import { CancelButton, SubmitButton, DeleteButton } from '~UI/index';
import TileForms from './FormTileType';

const TileForm = ({
  navSelected, handleSubmit, updateFunction, match, tile, tileType, onHide,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const featureToggles = useSelector((state: RootState) => state.featureToggles.featureToggles);
  const defaultLanguage = useSelector((state: RootState) => state.views.language);

  const [onCreationRules, setOnCreationRules] = useState([]);
  const [title, setTitle] = useState(tile.title || '');
  const [titleEN, setTitleEN] = useState(tile.titleEN || (defaultLanguage === 'en' ? tile.title : ''));
  const [titleFR, setTitleFR] = useState(tile.titleFR || (defaultLanguage === 'fr' ? tile.title : ''));
  const [titleES, setTitleES] = useState(tile.titleES || (defaultLanguage === 'es' ? tile.title : ''));
  const [language, setLanguage] = useState(defaultLanguage || localStorage.getItem('i18nextLng') || 'fr');

  const Form = TileForms[tileType] || TileForms.empty;

  const updateTitle = (value, lang) => {
    switch (lang) {
      case 'en':
        setTitleEN(value);
        break;
      case 'fr':
        setTitleFR(value);
        break;
      case 'es':
        setTitleES(value);
        break;
      default:
        break;
    }
  };

  const languageButtons = [{
    label: 'EN',
    value: 'en',
  },
  {
    label: 'FR',
    value: 'fr',
  },
  {
    label: 'ES',
    value: 'es',
  }];

  const languageTitleMap = {
    en: titleEN,
    fr: titleFR,
    es: titleES,
  };

  const displayTitle = languageTitleMap[language];

  const handleDelete = () => {
    const { selectedDashboard } = match.params;
    return reduxOperations.dashboards.deleteDashboardTile(selectedDashboard, tile.id)(dispatch);
  };

  if (shouldDisplayFormContent(navSelected)) {
    return (
      <div style={{ flexGrow: 1 }}>
        <form id="Dashboard__Edition__Popup__Form" onSubmit={e => e.preventDefault()}>
          <div
            className={classnames({
              hiddenDiv: navSelected === 'style' || navSelected === 'color',
            })}
          >
            <div
              style={{ display: 'flex' }}
            >
              <div style={{ flexGrow: 1 }}>
                <div
                  className={classnames({
                    hiddenDiv: navSelected !== 'parameters',
                  })}
                >
                  <div className="inputTitle">{t('title')}</div>
                  {featureToggles.features?.tileTranslations
                    ? (
                      <div className="languageInput">
                        <div className="titleInput">
                          <Input
                            value={displayTitle}
                            onChange={e => updateTitle(e, language)}
                            placeholder={t('valueInput')}
                          />
                        </div>
                        <ButtonGroup
                          buttons={languageButtons}
                          onChange={setLanguage}
                          value={language}
                        />
                        <input type="hidden" name="title" value={displayTitle} />
                        <input type="hidden" name="titleEN" value={titleEN} />
                        <input type="hidden" name="titleFR" value={titleFR} />
                        <input type="hidden" name="titleES" value={titleES} />
                      </div>
                    ) : (
                      <Input
                        value={title}
                        onChange={setTitle}
                        placeholder={t('valueInput')}
                        name="title"
                      />
                    )}
                </div>
                <Form
                  selectedTile={tile}
                  tileType={tileType}
                  navSelected={navSelected}
                />
              </div>
            </div>
          </div>

          <div
            className={classnames({
              hiddenDiv: navSelected !== 'style',
            })}
          >
            <FormStyle
              selectedObject={tile}
              type={tileType}
              isTile
            />
          </div>
          {navSelected === 'color' && (
            <RuleEditor
              selectedObjectId={tile.id}
              rules={tile.rules}
              tileWidgetType={tile.type}
              type={navSelected}
              updateFunction={updateFunction}
              modifiedItemId={match.params.selectedDashboard}
              onCreationRules={onCreationRules}
              setOnCreationRules={setOnCreationRules}
            />
          )}
          {(!tile || navSelected !== 'color') && (
            <div className="buttonsHolder flexSpaceBetween">
              <div>
                {tile && tile.id && <DeleteButton handleDelete={handleDelete} askConfirmation />}
              </div>
              <div>
                <CancelButton onClick={onHide} />
                <SubmitButton onClick={e => { handleSubmit(e, onCreationRules); setOnCreationRules([]); }} />
              </div>
            </div>
          )}
          <input
            type="hidden"
            name="type"
            readOnly
            value={tileType}
          />
        </form>
      </div>
    );
  }

  return (
    <div>
      <RuleEditor
        selectedObjectId={tile.id}
        rules={tile.rules}
        tileWidgetType={tile.type}
        type={navSelected}
        updateFunction={updateFunction}
        modifiedItemId={match.params.selectedDashboard}
      />
    </div>
  );
};

TileForm.propTypes = {
  tile: reducersTypes.dashboards.tile.isRequired,
  navSelected: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  updateFunction: PropTypes.func.isRequired,
  match: ReactRouterPropTypes.match.isRequired,
  tileType: PropTypes.string.isRequired,
  onHide: PropTypes.func.isRequired,
};

export { TileForm };
