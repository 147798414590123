import React, { useRef, useEffect } from 'react';
import * as monaco from 'monaco-editor/esm/vs/editor/editor.api.js';
import { CancelButton, SubmitButton } from '~components/UI';
import './JSONEditor.scss';

interface JSONEditorProps {
  json: object;
  onHide: () => void;
  onSubmit: (data: string) => void;
}

export const JSONEditor: React.FC<JSONEditorProps> = ({ json, onHide, onSubmit }) => {
  const editorRef = useRef<HTMLDivElement>(null);
  const editorInstanceRef = useRef<monaco.editor.IStandaloneCodeEditor | null>(null);

  const handleSubmit = () => {
    if (editorInstanceRef.current) {
      onSubmit(JSON.parse(editorInstanceRef.current.getValue()));
    }
  };

  useEffect(() => {
    const stringifiedJson = JSON.stringify(json, null, 2);
    if (editorRef.current) {
      editorInstanceRef.current = monaco.editor.create(editorRef.current, {
        value: stringifiedJson,
        language: 'json',
        theme: 'vs-dark',
        minimap: { enabled: false },
        lineNumbersMinChars: 3,
      });
    }

    const handleWindowResize = () => {
      editorInstanceRef.current?.layout();
    };

    window.addEventListener('resize', handleWindowResize);

    return () => {
      editorInstanceRef.current?.dispose();
      window.removeEventListener('resize', handleWindowResize);
    };
  }, [json]);

  return (
    <div className="jsonEditor">
      <div ref={editorRef} className="jsonEditor__editor" />
      <div className="jsonEditor__buttons">
        <CancelButton onClick={onHide} />
        <SubmitButton onClick={handleSubmit} />
      </div>
    </div>
  );
};

export default JSONEditor;
