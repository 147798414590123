import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import HttpStatus from 'http-status-codes';
import { Input } from '@intelligenceindustrielle/react-ui-components';
import { reduxOperations } from '~services';
import { PageTemplate, DefaultTable } from '~components/Pages';
import { ModalHandler, ResourcesHandler, SquaredAddButton } from '~UI';
import { showError, showSuccess } from '~utils/toast';
import { OperationPopupForm } from './OperationPopupForm';
import './OperationsPage.scss';

const OperationsPage = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const machines = useSelector(state => state.machines);
  const operations = useSelector(state => state.operations.operations);

  const [filterText, setFilterText] = useState('');

  const hasOrderAttribute = operations?.some(o => o.order);

  function handleDelete(id) {
    dispatch(reduxOperations.operations.deleteOperation(id))
      .then(() => showSuccess(t('showSuccessDeleted')))
      .catch(error => {
        if (error.code === HttpStatus.CONFLICT) {
          showError(t('operationInUse'));
        }
      });
  }
  const getEntries = () => operations
    .map(o => ({
      ...o,
      machines: o.machines.map(id => machines.find(m => m.id === id)?.name).join(', '),
    }))
    .sort((a, b) => {
      if (a.order && b.order) {
        return a.order - b.order;
      }
      if (!b.order) {
        return -1;
      }
      if (!a.order) {
        return 1;
      }
      return 0;
    })
    .filter(o => o.name.toLowerCase().includes(filterText.toLowerCase())
    || o.machines.toLowerCase().includes(filterText.toLowerCase()));

  const content = () => {
    let columnNames = [
      { name: t('name') },
      { name: t('machines') },
      { name: t('skuNumber') },
      { name: t('description') },
      { name: t('requiredQuantity') },
      { name: t('unitCompletionTime') },
    ];

    let entriesProperties = ['name', 'machines', 'skuNumber', 'description', 'requiredQuantity', 'unitCompletionTime'];

    if (hasOrderAttribute) {
      columnNames = [...columnNames, { name: t('scheduling') }];
      entriesProperties = [...entriesProperties, 'order'];
    }

    return (
      <PageTemplate
        sidebar
      >
        <div className="OperationsPage">
          <div className="topPage">
            <div>
              {`${t('searchByNameOrMachine')}:`}
              <Input
                clearable
                onChange={value => setFilterText(value)}
                onClear={() => setFilterText('')}
                placeholder={t('Search')}
                style={{
                  display: 'inline-block',
                  margin: '0 5px',
                  width: 250,
                }}
                value={filterText}
              />
            </div>
            <div>
              <ModalHandler
                Trigger={{
                  Component: SquaredAddButton,
                  props: {},
                }}
                Modal={{
                  Component: OperationPopupForm,
                  props: {},
                }}
              />
            </div>
          </div>
          <DefaultTable
            columnNames={columnNames}
            entriesProperties={entriesProperties}
            entries={getEntries()}
            editForm={OperationPopupForm}
            deleteFunction={id => handleDelete(id)}
          />
          {
          /*
          <br />
          <Table
            columns={[
              { name: t('name') },
              { name: t('skuNumber') },
              { name: t('description') },
              { name: t('requiredQuantity') },
              { name: t('unitCompletionTime') },
            ]}
            // deleteFunction={id => handleDelete(id)}
            // entriesProperties={['name', 'skuNumber', 'description', 'requiredQuantity', 'unitCompletionTime']}
            // entries={operations.filter(o => o.name.toLowerCase().includes(filterText.toLowerCase()))}
            // editForm={OperationPopupForm}
          />
          */
          }
        </div>
      </PageTemplate>
    );
  };

  return (
    <ResourcesHandler
      resources={[machines, operations]}
      resourceFetchers={[
        () => dispatch(reduxOperations.machines.fetchMachines()),
        () => dispatch(reduxOperations.operations.fetchOperations()),
      ]}
      getContents={content}
    />
  );
};

export { OperationsPage };
