import React from 'react';
import { v4 as uuidv4 } from 'uuid';

const stringWithBreakLines = (msg, size = 1) => {
  const lines = msg.split('\n');

  return (
    lines.map(line => (
      <div key={uuidv4()}>
        {line}
        {Array(size)
          .fill(5)
          .map(() => <br key={uuidv4()} />)}
      </div>
    ))
  );
};

export { stringWithBreakLines };
