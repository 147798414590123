import { EmptyWidget } from './EmptyWidget';
// alphabetical order
import ButtonWidget from './ButtonWidget';
import CircularChartWidget from './CircularChartWidget';
import DataEntryWidget from './DataEntryWidget';
import GaugeWidget from './GaugeWidget';
import InfoWidget from './InfoWidget';
import MachineStateWidget from './MachineStateWidget/MachineStateWidget';
import MediaWidget from './MediaWidget/MediaWidget';
import ScorecardWidget from './ScorecardWidget/ScorecardWidget';
import StopwatchWidget from './StopwatchWidget';
import TextWidget from './TextWidget';

export default {
  empty: EmptyWidget,
  // alphabetical order
  button: ButtonWidget,
  circularChart: CircularChartWidget,
  dataEntry: DataEntryWidget,
  gauge: GaugeWidget,
  info: InfoWidget,
  media: MediaWidget,
  machineState: MachineStateWidget,
  scorecard: ScorecardWidget,
  stopwatch: StopwatchWidget,
  text: TextWidget,
  value: ScorecardWidget,
};
