const formatSKU = skus => skus.map(sku => {
  const second = 1000;
  const minute = second * 60;
  const hour = minute * 60;

  const ms = `${sku.unitCompletionTime}ms`;
  const s = `${Math.trunc(((sku.unitCompletionTime % hour) % minute) / second)}s`;
  const min = `${Math.trunc((sku.unitCompletionTime % hour) / minute)}min`;
  const h = `${Math.trunc(sku.unitCompletionTime / hour)}h`;

  if (sku.unitCompletionTime < 1000) {
    return { ...sku, unitCompletionTime: ms };
  }
  if (sku.unitCompletionTime < 60000) {
    return { ...sku, unitCompletionTime: s };
  }
  if (sku.unitCompletionTime < 3600000) {
    if (s === '0s') {
      return { ...sku, unitCompletionTime: min };
    }
    return { ...sku, unitCompletionTime: `${min} ${s}` };
  }

  if (s === '0s' && min === '0min') {
    return { ...sku, unitCompletionTime: h };
  }
  if (s === '0s') {
    return { ...sku, unitCompletionTime: `${h} ${min}` };
  }
  return { ...sku, unitCompletionTime: `${h} ${min} ${s}` };
});

export default formatSKU;

const findCauseInSubMenu = (childCause, parent, subMenu, isByName) => {
  for (const subCause of subMenu) {
    if ((isByName && subCause.name === childCause.name) || (!isByName && subCause.id === childCause.id)) {
      return {
        childCause: subCause,
        parentCause: parent,
      };
    } if (subCause.subMenu) {
      const result = findCauseInSubMenu(childCause, subCause, subCause.subMenu, isByName);
      if (result) return result;
    }
  }
  return undefined;
};

export const getCauseInfosByNameOrId = (parentCause, childCause, causes, isByName = false) => {
  for (const cause of causes) {
    const result = findCauseInSubMenu(childCause, cause, cause.subMenu, isByName);
    if (result) return result;

    if ((isByName && cause.name === childCause.name) || (!isByName && cause.id === childCause.id)) {
      return {
        childCause: cause,
        parentCause: null,
      };
    }
  }
  // cause not found
  return undefined;
};
