import React from 'react';
import { useTranslation } from 'react-i18next';
import ErrorPage from './ErrorPage';

const Page404NotFound = () => {
  const { t } = useTranslation();
  return (
    <ErrorPage
      errorNumber={404}
      errorDefinition={t('pageNotFound')}
      errorDescription={t('pageRessourceNoPermissions')}
    />
  );
};

export default Page404NotFound;
