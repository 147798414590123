import { fetchOnceOperation } from '../fetchOperation';
import {
  getSettingsRequest,
  getSettingsResponse,
  updateSettingsRequest,
  updateSettingsResponse,
} from './actions';
import API from '~services/endpoints';

function fetchSettings() {
  return fetchOnceOperation(
    getSettingsRequest,
    getSettingsResponse,
    API.getSettings,
    state => state.settings.settings,
  );
}

function updateSettings(settingsInfo) {
  return dispatch => {
    dispatch(updateSettingsRequest({ id: '', settingsInfo }));
    return API.updateSettings(settingsInfo)
      .then(res => dispatch(updateSettingsResponse({ id: '', settings: res.settings })));
  };
}

export default {
  fetchSettings,
  updateSettings,
};
