import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ButtonGroup, Input } from '@intelligenceindustrielle/react-ui-components';
import { showUpload } from '~utils/toast';
import { RootState } from '~services/store';
import { FileUpload } from '~components/UI';
import { reduxOperations } from '~services/index';
import { FontAwesome } from '~UI/index';

import './Forms.scss';

function FormInfo({ selectedObject }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [URL, setURL] = useState(selectedObject ? selectedObject.url : null);

  const images = useSelector((state: RootState) => state.images.images);

  const mediaTypeButtons = [
    { label: t('image'), value: 'image' },
    { label: t('website'), value: 'iframe' },
  ];

  const designButtons = [
    {
      label: (
        <>
          {t('info')}
          &nbsp;&nbsp;
          <FontAwesome icon="info-circle" />
        </>
      ),
      value: 'info',
    },
    { label: t('button'), value: 'button' },
  ];

  const handleFileSelect = selectedFile => {
    showUpload(
      reduxOperations.images.addImage(selectedFile)(dispatch),
      t('uploadingImage'),
      t('showSuccessAdded'),
    )
      .then(res => {
        if (typeof res.payload.data === 'string') {
          setURL(res.payload.data);
        }
      });
  };

  return (
    <>
      <div className="inputTitle">{t('mediaType')}</div>
      <ButtonGroup
        buttons={mediaTypeButtons}
        defaultValue={selectedObject.mediaType || 'image'}
        name="mediaType"
      />
      <div className="inputTitle">URL</div>
      <div className="fileContainer">
        <Input
          className="fullwidth"
          value={URL}
          name="url"
          onChange={setURL}
          style={{ marginRight: '10px', flexGrow: 1 }}
        />
        <FileUpload
          onFileSelect={handleFileSelect}
          images={images}
          image={URL}
          setImage={setURL}
        />
      </div>
      <div className="inputTitle">{t('tileStyle')}</div>
      <ButtonGroup
        buttons={designButtons}
        defaultValue={selectedObject.style || 'info'}
        name="style"
      />
    </>
  );
}

FormInfo.propTypes = {
  selectedObject: PropTypes.shape({
    url: PropTypes.string,
    mediaType: PropTypes.string,
    style: PropTypes.string,
  }),
};

FormInfo.defaultProps = {
  selectedObject: {},
};

export default FormInfo;
