import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { ButtonGroup, Input, Select } from '@intelligenceindustrielle/react-ui-components';
import { sortArray } from '~utils/sort';
import { FormIframe } from './FormIframe';
import { FileUpload, EntriesForm } from '~components/UI';
import { FormImage } from './FormImage';
import { showUpload } from '~utils/toast';
import { RootState } from '~services/store';
import { reduxOperations } from '~services/index';

interface Param {
  key: string;
  readOnly?: boolean;
  value: string;
}

const FormMedia = ({ selectedObject }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const triggers = useSelector((state: RootState) => state.triggers);
  const images = useSelector((state: RootState) => state.images.images);

  const [mediaType, setMediaType] = useState(selectedObject.mediaType || 'image');
  const [image, setImage] = useState(selectedObject?.url || '');
  const [onClickTrigger, setOnClickTrigger] = useState(selectedObject?.onClickTrigger || '');

  const parameters = selectedObject.ruleParams ? JSON.parse(selectedObject.ruleParams) : {};
  const params = Object.keys(parameters)
    .map(key => ({
      key,
      value: parameters[key],
    }));
  params.push({
    key: '',
    value: '',
  });

  const initializeMachineIdList = paramsArg => {
    const listOfIndexes: number[] = [];
    paramsArg.forEach((elem, index) => {
      if (elem && elem.key.toLowerCase() === 'machineid') {
        listOfIndexes.push(index);
      }
    });
    return listOfIndexes;
  };

  const initialList = initializeMachineIdList(params);

  const [paramsState, setParams] = useState<Param[]>(params);
  const listOfIndexesToShow = initialList;

  const mediaButtons = [
    { label: `${t('image')} / ${t('video')}`, value: 'image' },
    { label: t('website'), value: 'iframe' },
  ];

  const handleParamsChange = paramsArg => setParams(paramsArg);

  const getParamsValue = () => {
    const value = {};
    paramsState.forEach(param => {
      if (param.key !== '') {
        value[param.key] = param.value;
      }
    });
    return JSON.stringify(value);
  };

  const handleFileSelect = selectedFile => {
    showUpload(
      reduxOperations.images.addImage(selectedFile)(dispatch),
      t('uploadingImage'),
      t('showSuccessAdded'),
    )
      .then(res => {
        if (typeof res.payload.data === 'string') {
          setImage(res.payload.data);
        }
      });
  };

  const trigger = triggers.find(trig => trig.id === onClickTrigger);
  const triggerOptions = sortArray('alphabetically', triggers, 'name').map(tr => ({ label: tr.name, value: tr.id }));

  return (
    <div>
      <div className="inputTitle">{t('mediaType')}</div>
      <ButtonGroup
        buttons={mediaButtons}
        name="mediaType"
        onChange={type => setMediaType(type)}
        value={mediaType}
      />

      <div className="inputTitle">URL</div>
      <div className="fileContainer">
        <Input
          className="fullwidth"
          value={image}
          onChange={setImage}
          name="url"
          placeholder={t('valueInput')}
          style={{ marginRight: '10px', flexGrow: 1 }}
        />
        <FileUpload
          onFileSelect={handleFileSelect}
          images={images}
          image={image}
          setImage={setImage}
        />
      </div>
      <div className="breakLine" />
      <div className="inputTitle">{t('rule')}</div>
      <Select
        value={onClickTrigger}
        name="onClickTrigger"
        onChange={setOnClickTrigger}
        options={triggerOptions}
        placeholder={t('selectOption')}
      />
      {
        trigger && !trigger.isActive && (
          <>
            <br />
            <span style={{ paddingLeft: '4px', color: 'red' }}>
              {t('ruleNotActive')}
            </span>
          </>
        )
      }

      <div className="inputTitle">{t('ruleParams')}</div>
      <EntriesForm
        params={paramsState}
        onChange={handleParamsChange}
        listOfIndexesToShow={listOfIndexesToShow}
      />
      <input
        type="hidden"
        name="ruleParams"
        value={getParamsValue()}
      />

      <div className="breakLine" />

      {mediaType === 'iframe' && (
        <FormIframe
          selectedObject={selectedObject}
        />
      )}
      {mediaType === 'image' && (
        <FormImage
          selectedObject={selectedObject}
        />
      )}
    </div>
  );
};

FormMedia.propTypes = {
  selectedObject: PropTypes.object,
};
FormMedia.defaultProps = {
  selectedObject: {},
};

export { FormMedia };
