import React from 'react';
import { PageTemplate } from '~components/Pages';
import HomeGrid from './HomeGrid';
import Bookmarks from './Bookmarks';
import './HomePage.scss';

const HomePage = () => (
  <PageTemplate
    NPSPopups
    noScrollBar
    pageTemplateClass="HomePageTemplate"
    sidebar
  >
    <div className="HomePage">
      <HomeGrid />
      <Bookmarks />
    </div>
  </PageTemplate>
);

export default HomePage;
