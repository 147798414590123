import types from './types';

const getSettingsRequest = () => ({
  type: types.GET_SETTINGS_REQUEST,
});

const getSettingsResponse = res => ({
  type: types.GET_SETTINGS_RESPONSE,
  payload: res,
});

const updateSettingsRequest = res => ({
  type: types.UPDATE_SETTINGS_REQUEST,
  payload: res,
});

const updateSettingsResponse = res => ({
  type: types.UPDATE_SETTINGS_RESPONSE,
  payload: res,
});

export {
  getSettingsRequest,
  getSettingsResponse,
  updateSettingsRequest,
  updateSettingsResponse,
};
