import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { RootState } from '~services/store';
import { reducersTypes } from '~services/index';
import { invertColor, getTitle } from '~utils/index';
import { getApproximateFontSize } from '~utils/responsiveValue';
import TextWidgetComponent from './WidgetComponents/TextWidgetComponent';

const TITLE_FONT_SIZE_HEIGHT = 0.22;
const TITLE_POSITION = 0.15;

const CircleWidget = ({
  x, y, w, h, backgroundColor, content, widget,
}) => {
  const featureToggles = useSelector((state: RootState) => state.featureToggles.featureToggles);
  const language = useSelector((state: RootState) => state.views.language);

  const title = featureToggles.features?.tileTranslations ? getTitle(widget, language) : widget?.title || '';

  const d = Math.min(w, h);
  const r = d / 2;
  const cx = x + r;
  const cy = y + r;
  const { titleSize } = widget;
  const titleFontSize = titleSize || getApproximateFontSize(title, d, d * TITLE_FONT_SIZE_HEIGHT);

  return (
    <>
      <circle cx={cx} cy={cy} r={r} fill={backgroundColor} stroke="none" />
      <text
        x={x + d / 2}
        y={y + d * TITLE_POSITION}
        fill={invertColor(backgroundColor)}
        style={{ fontSize: `${titleFontSize}px`, fontWeight: 'bold' }}
      >
        {title}
      </text>
      <TextWidgetComponent
        widget={widget}
        x={x}
        y={y}
        w={d}
        h={d}
        content={content}
        backgroundColor={backgroundColor}
      />
    </>
  );
};

CircleWidget.propTypes = {
  widget: reducersTypes.topviews.widget,
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
  w: PropTypes.number.isRequired,
  h: PropTypes.number.isRequired,
  backgroundColor: PropTypes.string,
  content: PropTypes.string,
};
CircleWidget.defaultProps = {
  widget: {},
  backgroundColor: '',
  content: '',
};

export default CircleWidget;
