import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Alert } from 'react-bootstrap';
import LogoBlack from '~images/Logo_header_black.png';
import LogoWhite from '~images/Logo_header_white.png';
import { reduxOperations } from '~services/index';
import FontAwesome from '~UI/FontAwesome';
import Footer from '~components/Pages/Footer';
import { getFormData } from '~utils/index';
import { initGoogleAnalytics } from '~utils/googleAnalytics';
import { MIN_WIDTH_LOGIN } from '~utils/constants';
import { showError } from '~utils/toast';
import { RootState } from '~services/store';
import { initializeSocket } from '~services/socket';
import './Login.scss';

const LoginPage = () => {
  const dispatch = useDispatch();
  const featureToggles = useSelector((state : RootState) => state.featureToggles.featureToggles);
  const { t } = useTranslation();

  const [isLoginCollapsed, setIsLoginCollapsed] = useState(false);
  const [showLoginError, setShowLoginError] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const handleResize = () => setIsLoginCollapsed(window.innerWidth < MIN_WIDTH_LOGIN);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleSignup = () => {
    dispatch(reduxOperations.session.updateIsSignup(true));
  };

  const handleConnexion = e => {
    const formData = getFormData('Login__inner__formulaire');
    e.preventDefault();
    dispatch(reduxOperations.featureToggles.fetchFeatureToggles() as any);
    dispatch(reduxOperations.session.login(formData.username, formData.password) as any)
      .then(res => {
        if (res.payload.loggedin) {
          dispatch(reduxOperations.settings.fetchSettings() as any);
          initGoogleAnalytics();
          initializeSocket();
          dispatch(reduxOperations.views.setLanguage(res.payload.infos.language || 'fr'));
          // TODO: THEME put back the commented code when light theme is finished
          // setTheme(localStorage.getItem('theme'));
          dispatch(reduxOperations.session.setTheme('Dark'));
          dispatch(reduxOperations.users.fetchUserPermissions(res.payload.infos.id) as any);
        } else {
          showError(res.payload.response);
        }
      })
      .catch(() => {
        setShowLoginError(true);
      });

    if (document.getElementById('password')) {
      (document.getElementById('password') as HTMLInputElement).value = '';
    }
  };

  const handleMicrosoftSSO = e => {
    e.preventDefault();
    dispatch(reduxOperations.session.loginMicrosoft() as any)
      .then(res => {
        if (res.payload.url) {
          window.location.href = res.payload.url;
        } else {
          showError(res.payload.response);
        }
      })
      .catch(() => {
        setShowLoginError(true);
      });
  };

  const theme = localStorage.getItem('theme');

  return (
    <div className="Login">
      <div className="headerLogin">
        <img id="ii-icon" src={theme === 'Dark' ? LogoWhite : LogoBlack} alt="K2" height="25" />
      </div>
      <div className="middle">
        <div className={`inner ${isLoginCollapsed ? 'smallInner' : ''}`}>
          <img src={theme === 'Dark' ? LogoWhite : LogoBlack} className="app-logo" alt="logo" />
          {
            showLoginError && (
              <Alert bsStyle="danger">
                <strong>{t('invalidLogin')}</strong>
              </Alert>
            )
          }
          <form id="Login__inner__formulaire" className="formulaire" onSubmit={handleConnexion}>
            <input
              type="text"
              name="username"
              autoFocus
              placeholder={`${t('email')} / ${t('username')}`}
              className={isLoginCollapsed ? 'smallInput' : ''}
            />
            <div>
              <input
                id="password"
                type={showPassword ? 'text' : 'password'}
                name="password"
                placeholder={t('password')}
                className={isLoginCollapsed ? 'smallInput' : ''}
              />
              <FontAwesome
                icon={showPassword ? 'eye-slash' : 'eye'}
                className="showPasswordIcon"
                onClick={() => setShowPassword(!showPassword)}
              />
            </div>

            <button
              type="submit"
              className={isLoginCollapsed ? 'smallInput' : ''}
            >
              {t('connection')}
            </button>
          </form>
          <button type="button" className="MicrosoftSSO" onClick={handleMicrosoftSSO}>
            <div className="MicrosoftSSO__image">
              <FontAwesome icon="microsoft" />
            </div>
            <div className="MicrosoftSSO__text">
              Sign in with Microsoft
            </div>
          </button>
          {featureToggles && featureToggles.pages.signup && (
            <Link
              key="/signup"
              to="/signup"
              className="signup"
              onClick={handleSignup}
            >
              {t('signup')}
            </Link>
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default LoginPage;
