import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withTranslation } from 'react-i18next';
import {
  DeleteButton, CancelButton, SubmitButton, DefaultModal, CheckboxToggle,
} from '~UI';
import { getFormData } from '~utils';
import { reduxOperations, reducersTypes } from '~services';
import { showSuccess, showError } from '~utils/toast';
import { verifyVariableName } from '~utils/parser';

import './InputsPage.scss';

const propTypes = {
  streams: reducersTypes.streams.isRequired,
  variables: reducersTypes.variables.isRequired,
  machines: reducersTypes.machines.isRequired,
  addStreamProperty: PropTypes.func.isRequired,
  updateStreamProperty: PropTypes.func.isRequired,
  deleteStreamProperty: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,

  modifiedItemId: PropTypes.string,
  streamId: PropTypes.string.isRequired,
  pin: PropTypes.string,
  onHide: PropTypes.func,
  show: PropTypes.bool,
};

const defaultProps = {
  onHide: () => { },
  show: false,
  pin: null,
  modifiedItemId: 'add',
};

class InputCRUDPage extends Component {
  constructor(props) {
    super(props);
    const { pin, streamId, modifiedItemId, streams } = props;
    let property = {};
    if (modifiedItemId !== 'add') {
      property = streams
        .find(stream => stream.id === streamId).properties
        .find(prop => prop.id === modifiedItemId);
    }
    if (pin) {
      property.name = pin;
    }
    this.state = {
      readOnlyName: pin,
      property,
      isInput: typeof property.isInput !== 'boolean' || property.isInput ? 'input' : 'output',
      isAnalog: typeof property.isAnalog === 'boolean' && property.isAnalog ? 'analog' : 'digital',
    };
  }

  componentDidUpdate(prevProps) {
    const {
      show, pin, streamId, modifiedItemId, streams,
    } = this.props;
    if (show !== prevProps.show) {
      let property = {};
      if (modifiedItemId !== 'add') {
        property = streams
          .find(stream => stream.id === streamId).properties
          .find(prop => prop.id === modifiedItemId);
      }
      if (pin) {
        property.name = pin;
      }
      this.setState({
        readOnlyName: pin,
        property,
        isInput: typeof property.isInput !== 'boolean' || property.isInput ? 'input' : 'output',
        isAnalog: typeof property.isAnalog === 'boolean' && property.isAnalog ? 'analog' : 'digital',
      });
    }
  }

  createProperty = formData => {
    const { streamId, addStreamProperty, onHide, t } = this.props;

    if (!formData.lerp) {
      delete formData.in_min;
      delete formData.in_max;
      delete formData.out_min;
      delete formData.out_max;
    }

    addStreamProperty(streamId, formData)
      .then(() => {
        showSuccess(t('operationSucceeded'));
        onHide();
      })
      .catch(() => showError(t('operationFailed')));
  };

  handleSubmit = e => {
    e.preventDefault();
    const {
      streams, streamId, updateStreamProperty, onHide, modifiedItemId, variables, machines, t,
    } = this.props;
    const { isInput, isAnalog } = this.state;

    const propertyData = getFormData('streamPropertyForm');

    propertyData.isInput = isInput === 'input';
    if (propertyData.name === 'GPIO4' || propertyData.name === 'GPIO5') {
      propertyData.isAnalog = isAnalog === 'analog';
    } else {
      propertyData.isAnalog = false;
    }

    if (propertyData.metadata) {
      propertyData.type = 'metadata';
    } else {
      propertyData.type = 'data';
    }
    delete propertyData.metadata;

    if (verifyVariableName(propertyData.variable)) {
      return;
    }

    const properties = [].concat(...streams.map(s => s.properties));
    const kpis = [].concat(...machines.map(m => m.kpis || []));
    const all = [...properties, ...variables, ...kpis];
    const sameName = all.find(variable => {
      if (variable.variable === propertyData.variable) {
        return !modifiedItemId || modifiedItemId !== variable.id;
      }
      return false;
    });
    if (sameName) {
      showError(t('variableNameExistsError'));
      return;
    }

    if (modifiedItemId === 'add') {
      this.createProperty(propertyData);
      return;
    }

    let isLerpValueOk = true;
    if (propertyData.lerp) {
      if (propertyData.in_min === null || propertyData.in_min === undefined
        || propertyData.in_max === null || propertyData.in_max === undefined
        || propertyData.out_min === null || propertyData.out_min === undefined
        || propertyData.out_max === null || propertyData.out_max === undefined) {
        isLerpValueOk = false;
        showError(t('showErrorFields'));
      }
    } else {
      delete propertyData.in_min;
      delete propertyData.in_max;
      delete propertyData.out_min;
      delete propertyData.out_max;
    }

    if (modifiedItemId && isLerpValueOk) {
      updateStreamProperty(streamId, modifiedItemId, propertyData)
        .then(() => {
          showSuccess(t('operationSucceeded'));
          onHide();
        })
        .catch(() => showError(t('operationFailed')));
    }
  };

  handleDelete = async () => {
    const { deleteStreamProperty, streamId, onHide, modifiedItemId } = this.props;
    deleteStreamProperty(streamId, modifiedItemId);
    onHide();
  };

  handleChange = () => {
    const propertyData = getFormData('streamPropertyForm');
    this.setState(prevState => ({
      property: propertyData,
      isInput: propertyData.isInput,
      isAnalog: propertyData.isAnalog || prevState.isAnalog,
    }));
  };

  toggleNormalize = () => {
    this.setState(prevState => ({ property: { ...prevState.property, lerp: !prevState.property.lerp } }));
  };

  renderIsInputButtons = () => {
    const { isInput } = this.state;
    const { t } = this.props;
    return (
      <div className="inputTitle">
        {`${t('input')}`}
        <input
          name="isInput"
          value="input"
          onChange={this.handleChange}
          type="radio"
          checked={isInput === 'input' ? 'checked' : null}
        />

        {`${t('output')}`}
        <input
          name="isInput"
          value="output"
          onChange={this.handleChange}
          type="radio"
          checked={isInput === 'output' ? 'checked' : null}
        />
      </div>
    );
  };

  renderDigitalOrAnalogic = () => {
    const { t } = this.props;
    const { property, isAnalog } = this.state;
    return (property.name === 'GPIO4' || property.name === 'GPIO5') && (
      <div className="inputTitle">
        <div style={{ margin: '8px 0 8px 0' }}>
          {`${t('digitalSignal')}`}
          <input
            name="isAnalog"
            value="digital"
            onChange={this.handleChange}
            type="radio"
            checked={isAnalog === 'digital' ? 'checked' : null}
          />
          {`${t('analogicSignal')}`}
          <input
            name="isAnalog"
            value="analog"
            onChange={this.handleChange}
            type="radio"
            checked={isAnalog === 'analog' ? 'checked' : null}
          />
        </div>
      </div>
    );
  };

  renderNormalizeInputs = () => {
    const { property } = this.state;
    const { t } = this.props;
    return (
      <>
        <div className="inputTitle">
          {t('normalize')}
          &nbsp;
          <CheckboxToggle
            name="lerp"
            onChange={this.toggleNormalize}
            defaultChecked={property.lerp}
          />
        </div>
        <div className={`NormalizedInputs ${property.lerp ? 'show' : ''}`}>
          <div className="inputTitle">
            {`${t('minValInput')}: `}
            <input
              name="in_min"
              type="number"
              step="0.01"
              onChange={this.handleChange}
              defaultValue={Object.prototype.hasOwnProperty.call(property, 'in_min') ? property.in_min : null}
            />
          </div>
          <div className="inputTitle">
            {`${t('maxValInput')}: `}
            <input
              name="in_max"
              type="number"
              step="0.01"
              onChange={this.handleChange}
              defaultValue={Object.prototype.hasOwnProperty.call(property, 'in_max') ? property.in_max : null}
            />
          </div>
          <div className="inputTitle">
            {`${t('minValOutput')}: `}
            <input
              name="out_min"
              type="number"
              step="0.01"
              onChange={this.handleChange}
              defaultValue={Object.prototype.hasOwnProperty.call(property, 'out_min') ? property.out_min : null}
            />
          </div>
          <div className="inputTitle">
            {`${t('maxValOutput')}: `}
            <input
              name="out_max"
              type="number"
              step="0.01"
              onChange={this.handleChange}
              defaultValue={Object.prototype.hasOwnProperty.call(property, 'out_max') ? property.out_max : null}
            />
          </div>
        </div>
      </>
    );
  };

  render() {
    const {
      t, show, onHide, modifiedItemId, streams, streamId,
    } = this.props;
    const { property, readOnlyName } = this.state;

    const stream = streams.find(s => s.id === streamId);

    return (
      <DefaultModal
        show={show}
        title={`${stream.name} - ${property.name || t('addStreamProperty')}`}
        closePopup={onHide}
        children={(
          <form
            id="streamPropertyForm"
            onSubmit={this.handleSubmit}
          >
            <div className="inputTitle">{t('name')}</div>
            <input
              name="name"
              onChange={this.handleChange}
              defaultValue={property.name || null}
              type="text"
              readOnly={!!readOnlyName}
              placeholder="GPIO3"
            />
            {
              property.name !== 'shutdown' && property.name !== 'alive' && (
                <>
                  {this.renderIsInputButtons()}
                  {this.renderDigitalOrAnalogic()}
                </>
              )
            }

            <div className="inputTitle">{t('variable')}</div>
            <input
              name="variable"
              type="text"
              onChange={this.handleChange}
              defaultValue={property.variable ? property.variable : null}
              placeholder="Machine1_GPIO3"
            />

            {
              (property.name === 'shutdown' || property.name === 'alive') && (
                <div className="inputTitle">
                  {property.name === 'shutdown' ? t('shutdownDescription') : t('aliveDescription')}
                  &nbsp;
                  <CheckboxToggle
                    name="metadata"
                    defaultChecked={property.type === 'metadata'}
                  />
                </div>
              )
            }

            {
              property.name !== 'shutdown' && property.name !== 'alive' && (
                <>
                  {this.renderNormalizeInputs()}
                  <div className="inputTitle">{t('units')}</div>
                  <input
                    name="units"
                    type="text"
                    onChange={this.handleChange}
                    defaultValue={property.units || null}
                    placeholder={t('units')}
                  />
                </>
              )
            }

            <div className="buttonsHolder flexSpaceBetween">
              {
                modifiedItemId !== 'add' ? (
                  <DeleteButton handleDelete={this.handleDelete} askConfirmation />
                ) : <div />
              }
              <div>
                <CancelButton onClick={onHide} />
                <SubmitButton
                  label={modifiedItemId !== 'add' ? t('modify')
                    : t('add')}
                />
              </div>
            </div>
          </form>
        )}
      />
    );
  }
}

InputCRUDPage.propTypes = propTypes;
InputCRUDPage.defaultProps = defaultProps;

function mapStateToProps(state) {
  return {
    streams: state.streams,
    variables: state.variables,
    machines: state.machines,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    // fetch
    fetchFeatureToggles: reduxOperations.featureToggles.fetchFeatureToggles,
    addStreamProperty: reduxOperations.streams.addStreamProperty,
    updateStreamProperty: reduxOperations.streams.updateStreamProperty,
    deleteStreamProperty: reduxOperations.streams.deleteStreamProperty,
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(InputCRUDPage));
