import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import colorString from 'color-string';
import k2Sensor from '~images/k2.gif';
import PINS from './pins.json';
import RECTS from './rects.json';
import InputCRUDPage from '../InputCRUDPage';
import './Inputs3D.scss';

const propTypes = {
  streamId: PropTypes.string.isRequired,
  properties: PropTypes.shape({
    find: PropTypes.func.isRequired,
  }).isRequired,
  isUserAllowedToConfigure: PropTypes.bool.isRequired,
};

const getTipPosition = pin => {
  if (!pin) { return { top: 0, left: 0 }; }
  const x = pin.coords[0];
  const y = pin.coords[1] + 2;

  return { top: `${y}px`, left: `${x}px` };
};

const drawRectangle = (rect, index) => (
  <rect
    key={`Rect_${index}`}
    className={rect.clickable ? ['pinRectangle', 'pinHover'].join(' ') : 'pinRectangle'}
    x={rect.coords[0]}
    y={rect.coords[1]}
    rx="10"
    fill={rect.color}
    stroke="#000000"
    strokeWidth="2"
    width={rect.coords[2]}
    height={rect.coords[3]}
    opacity="0"
    pointerEvents={rect.clickable ? 'fill' : 'none'}
  />
);

const Inputs3D = ({ streamId, properties, isUserAllowedToConfigure }) => {
  const img = useRef(null);

  const [image, setImage] = useState(k2Sensor);
  const [popupId, setPopupId] = useState('');

  useEffect(() => {
    img.current.src = image;

    return () => {
      img.current.src = '';
      setImage('');
    };
  }, []);

  const showPopup = popupIdArg => {
    setPopupId(popupIdArg);
  };

  const renderPopup = (id, pin) => (
    <InputCRUDPage
      streamId={streamId}
      modifiedItemId={id}
      pin={pin}
      onHide={() => showPopup(null)}
      show={id ? popupId === id : popupId === `add_${pin}`}
    />
  );

  const drawPin = (pin, index) => {
    const property = properties.find(p => p.name === pin.pin);
    const pinColor = (!pin.name.startsWith('D') || property)
      ? pin.color
      : colorString.to.hex(
        colorString.get.rgb(pin.color)
          .slice(0, 3)
          .map(value => Math.round(value / 2)),
      );

    return (
      <>
        {renderPopup(property && property.id, pin.pin)}
        <circle
          key={`Circle_${index}`}
          className={pin.clickable && isUserAllowedToConfigure ? ['pinCircle', 'pinHover'].join(' ') : 'pinCircle'}
          cx={pin.coords[0]}
          cy={pin.coords[1]}
          r="12"
          fill={pinColor}
          opacity="0"
          onClick={() => {
            if (isUserAllowedToConfigure) {
              showPopup(property ? property.id : `add_${pin.pin}`);
            }
          }}
          pointerEvents={pin.clickable && isUserAllowedToConfigure ? 'fill' : 'none'}
        />
      </>
    );
  };

  return (
    <div className="sensor_container">
      <svg
        className="sensor_pins"
        width="1000px"
        height="678.44px"
      >
        {RECTS.map((rect, index) => drawRectangle(rect, index))}
        {PINS.map((pin, index) => drawPin(pin, index))}
      </svg>

      <img
        className="sensor_image"
        ref={node => {
          img.current = node;
          return img;
        }}
        alt="k2_sensor"
        src={image}
        width="1000px"
        height="678.44px"
      />

      {
        PINS.map((pin, index) => (
          <span
            key={index}
            className="tooltip"
            style={{ ...getTipPosition(pin) }}
          >
            {pin.symbol}
          </span>
        ))
      }
    </div>
  );
};

Inputs3D.propTypes = propTypes;

export default Inputs3D;
