import ActionParamForm from './ActionParamForm';
// alhpabetical order
import EmailForm from './EmailActionForm';
import EventForm from './EventActionForm';
import { MachineParamActionForm } from './MachineParamActionForm';
import MachineStateForm from './MachineStateActionForm';
import MQTTForm from './MQTTActionForm';
import PartForm from './PartActionForm';
import PerformanceForm from './PerformanceActionForm';
import PushNotificationForm from './PushNotificationActionForm';
import SMSForm from './SMSActionForm';
import StopwatchForm from './StopwatchActionForm';
import VariableForm from './VariableActionForm';

export default {
  EmailAction: EmailForm,
  EventAction: EventForm,
  MachineParamAction: MachineParamActionForm,
  MachineStateAction: MachineStateForm,
  MQTTAction: MQTTForm,
  PartAction: PartForm,
  PerformanceAction: PerformanceForm,
  PushNotificationAction: PushNotificationForm,
  SMSAction: SMSForm,
  StopwatchAction: StopwatchForm,
  VariableAction: VariableForm,
};

export {
  ActionParamForm,
};
