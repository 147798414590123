import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import IdleTimer from 'react-idle-timer';
import { useQuery } from '@tanstack/react-query';
import API from '~services/endpoints';
import Tick from '~utils/Tick';
import { getTitle } from '~utils';
import { getSocket } from '~services/socket';
import { FontAwesome } from '~components/UI';
import { canNavigateTime, navigateTimeUtil } from '~utils/navigateTime';
import { getMetric, getStatisticsFrom } from '~components/utils';
import { useShift } from '~utils/hooks';
import { dateToStringWithoutYearAndMillis, getDataRangeStartEnd } from '~utils/time';
import { round } from '~utils/math';
import CircularChart from './CircularChartSVG';

const FETCH_STATS_DELAY_MS = 5000;
const evaluateDelay = 10; // in seconds

const CircularChartMachine = ({
  selectedObject, dimension, textColor, isTile, fetchDelay,
}) => {
  const socket = getSocket();
  const { t } = useTranslation();
  const machines = useSelector(state => state.machines);
  const featureToggles = useSelector(state => state.featureToggles.featureToggles);
  const language = useSelector(state => state.views.language);

  const title = featureToggles.features?.tileTranslations ? getTitle(selectedObject, language) : selectedObject?.title || '';

  const [currentShift, shifts] = useShift(selectedObject.machineId);
  const [goal, setGoal] = useState(selectedObject.goal);

  const [timePeriod, setTimePeriod] = useState(getDataRangeStartEnd(selectedObject.intervalType || 'shift', currentShift));
  const [hasUsedArrows, setHasUsedArrows] = useState(false);

  const idleTimerRef = useRef(null);

  const handleSocketData = socketData => {
    if (selectedObject.objectiveType === 'variable' && socketData.id === selectedObject.goal) {
      const goalToSet = round(socketData.value, 2);
      if (goalToSet >= 0 && goalToSet <= 100) {
        setGoal(goalToSet);
        return;
      }
      setGoal(-1);
    }
  };

  const machine = machines.find(m => m.id === selectedObject.machineId);
  if (!machine) {
    return (
      <h3>{t('machineIsNotConfiguredOrDeleted')}</h3>
    );
  }

  const { data: statistics, refetch } = useQuery({
    queryKey: ['fetchGaugeMachineStats', selectedObject.machineId, selectedObject.intervalType || 'shift',
      selectedObject.currentOperation, selectedObject.currentSKUNumber,
      selectedObject.currentWorkOrder, selectedObject.currentWorkOrder, selectedObject.currentOperation && getStatisticsFrom(selectedObject.valueType || 'useRate') === 'operation', timePeriod],
    queryFn: () => {
      const {
        machineId,
        valueType = 'useRate',
        intervalType = 'shift',
        currentOperation = false,
        currentSKUNumber = false,
        currentWorkOrder = false,
      } = selectedObject;

      if (selectedObject.valueType === 'operationProgress') {
        selectedObject.currentOperation = true;
      }

      if (currentOperation && getStatisticsFrom(valueType) === 'operation') {
        const filter = {
          machineId,
        };
        if (intervalType !== 'always') {
          filter.timestamp = {
            $gt: timePeriod.start,
            $lt: timePeriod.end,
          };
        }

        return API.getOperationStats(machine.params.operation, filter);
      }
      const filter = {
        currentOperation,
        currentSKUNumber,
        currentWorkOrder,
        timestamp: {
          $gt: timePeriod.start,
          $lt: timePeriod.end,
        },
      };

      return API.getMachineStats(machineId, filter);
    },
    refetchInterval: fetchDelay || FETCH_STATS_DELAY_MS,
    keepPreviousData: true,
  });

  const navigateTime = goBackward => {
    const { newTimePeriod, hasUsedArrows: newHasUsedArrows } = navigateTimeUtil(goBackward, timePeriod, shifts, selectedObject.intervalType || 'shift');
    setTimePeriod(newTimePeriod);
    setHasUsedArrows(newHasUsedArrows);
    refetch();
  };

  const updateTimePeriod = () => {
    if (!hasUsedArrows) {
      const newTimePeriod = getDataRangeStartEnd(selectedObject.intervalType || 'shift', currentShift);
      setTimePeriod(newTimePeriod);
    }
  };

  const getInterval = () => {
    const metric = getMetric(selectedObject.valueType || 'useRate');
    if (selectedObject.valueType === 'operationProgress') {
      return { max: statistics?.requiredQuantity, min: 0 };
    }
    if (metric === 'unit') {
      return { max: selectedObject.intervalMax, min: 0 };
    }
    return null;
  };

  useEffect(() => {
    const listener = data => handleSocketData(data);
    socket.on('value', listener);

    if (selectedObject.objectiveType === 'variable' && selectedObject.goal) {
      API.getValues(selectedObject.goal, {}, 1).then(response => {
        const goalToSet = response?.values[0] && round(response.values[0].value, 2);
        if (goalToSet >= 0 && goalToSet <= 100) {
          setGoal(goalToSet);
          return;
        }
        setGoal(-1);
      });
    } else {
      setGoal(selectedObject.goal);
    }

    return () => {
      if (socket) {
        socket.removeListener('value', listener);
      }
    };
  }, [selectedObject, currentShift]);

  useEffect(() => {
    const newTimePeriod = getDataRangeStartEnd(selectedObject.intervalType || 'shift', currentShift);
    setTimePeriod(newTimePeriod);
  }, [selectedObject, selectedObject.intervalType, currentShift]);

  const handleOnIdle = () => {
    refetch();
    idleTimerRef.current.reset();
  };

  const handleOnActive = () => {
    refetch();
  };

  useEffect(() => {
    Tick.subscribe(updateTimePeriod, evaluateDelay);

    return () => {
      Tick.unsubscribe(updateTimePeriod);
    };
  }, [currentShift, hasUsedArrows]);

  useEffect(() => {
    setHasUsedArrows(false);
  }, [selectedObject.intervalType]);

  const navigationText = hasUsedArrows
    ? `${t('from2')} ${dateToStringWithoutYearAndMillis(new Date(timePeriod.start))} ${t('to2')} ${dateToStringWithoutYearAndMillis(new Date(timePeriod.end))}`
    : '';

  return (
    <div className={`timeNavigationContainer ${!statistics && 'hide'}`}>
      <IdleTimer
        ref={idleTimerRef}
        onIdle={handleOnIdle}
        onActive={handleOnActive}
        debounce={250}
        timeout={1000 * 90}
      />
      <div>{navigationText}</div>
      <div className="timeNavigation">
        {selectedObject.showArrows && (
          <FontAwesome
            icon="arrow-left"
            className="timeNavigationButton"
            style={{
              opacity: !canNavigateTime(true, timePeriod, shifts, selectedObject.intervalType || 'shift') && 0.4,
              cursor: !canNavigateTime(true, timePeriod, shifts, selectedObject.intervalType || 'shift') && 'default',
            }}
            onClick={() => navigateTime(true)}
          />
        )}
        <CircularChart
          dimension={dimension}
          selectedObject={selectedObject}
          textColor={textColor}
          currentValue={statistics ? statistics[selectedObject.valueType || 'useRate'] : 0}
          metric={getMetric(selectedObject.valueType || 'useRate')}
          interval={getInterval()}
          showGoalToggle={typeof selectedObject.showGoalToggle === 'boolean' ? selectedObject.showGoalToggle : typeof selectedObject.goal === 'number'} // for backward compatibiltity with no toogle
          goal={getMetric(selectedObject.valueType) === 'percentage' ? goal / 100 : goal}
          isTile={isTile}
          title={title}
          aboveGoalColor={selectedObject.aboveGoalColor}
          belowGoalColor={selectedObject.belowGoalColor}
          showArrows={selectedObject.showArrows}
        />
        {selectedObject.showArrows && (
          <FontAwesome
            icon="arrow-right"
            className="timeNavigationButton"
            style={{
              opacity: !canNavigateTime(false, timePeriod, shifts, selectedObject.intervalType || 'shift') && 0.4,
              cursor: !canNavigateTime(false, timePeriod, shifts, selectedObject.intervalType || 'shift') && 'default',
            }}
            onClick={() => navigateTime(false)}
          />
        )}
      </div>
    </div>
  );
};

CircularChartMachine.propTypes = {
  dimension: PropTypes.object.isRequired,
  selectedObject: PropTypes.shape({
    currentOperation: PropTypes.bool,
    currentSKUNumber: PropTypes.bool,
    currentWorkOrder: PropTypes.bool,
    objectiveType: PropTypes.string,
    goal: PropTypes.number,
    id: PropTypes.string,
    intervalMax: PropTypes.number,
    intervalType: PropTypes.string,
    machineId: PropTypes.string,
    showGoalToggle: PropTypes.bool,
    title: PropTypes.string,
    valueType: PropTypes.string,
    aboveGoalColor: PropTypes.string,
    belowGoalColor: PropTypes.string,
    showArrows: PropTypes.bool,
  }).isRequired,
  textColor: PropTypes.string.isRequired,
  isTile: PropTypes.bool,
  fetchDelay: PropTypes.number,
};
CircularChartMachine.defaultProps = {
  fetchDelay: FETCH_STATS_DELAY_MS,
  isTile: false,
};

export default CircularChartMachine;
