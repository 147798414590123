import { fetchOnceOperation } from '../fetchOperation';
import {
  getFeatureTogglesRequest,
  getFeatureTogglesResponse,
} from './actions';
import API from '../endpoints';

function fetchFeatureToggles() {
  return fetchOnceOperation(
    getFeatureTogglesRequest,
    getFeatureTogglesResponse,
    API.getFeatureToggles,
    state => state.featureToggles.featureToggles,
  );
}

export default {
  fetchFeatureToggles,
};
