import PropTypes from 'prop-types';
import { combineReducers } from 'redux';
import nullable from 'prop-types-nullable';

import types from './types';

const featureTogglesPropTypes = PropTypes.shape({
  pages: PropTypes.shape({
    topview: PropTypes.bool,
    signup: PropTypes.bool,
  }),
  buttons: PropTypes.shape({
    view3D: PropTypes.bool,
    progressBar: PropTypes.bool,
    showAlive: PropTypes.bool,
    popupButton: PropTypes.bool,
  }),
});

function featureToggles(state = null, action) {
  switch (action.type) {
    case types.GET_FEATURE_TOGGLES_RESPONSE:
      return action.payload.featureToggles;

    default:
      return state;
  }
}

export const propTypes = {
  featureToggles: nullable(featureTogglesPropTypes),
};

export default combineReducers({
  featureToggles,
});
