import React, { useState, useEffect } from 'react';
import useInterval from 'use-interval';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import deepEqual from 'deep-equal';
import { useTranslation } from 'react-i18next';
import FontAwesome from '~UI/FontAwesome/FontAwesome';
import { reduxOperations } from '~services/index';
import { RootState } from '~services/store';
import { invertColor } from '~utils/index';
import { getMaintenance } from '~services/maintenances/endpoints';
import ResourcesHandler from '~UI/Wrappers/ResourcesHandler';
import directLinks from '~utils/directLinks';
import { MIN_WIDTH_FULL_FOOTER } from '~utils/constants';
import { dateToHHmm } from '~utils/time/utils';
import { websiteOptions } from './websiteOptions';
import pkg from '../../../../package.json';
import './Footer.scss';

type MaintenanceObject = {
  id: string;
  type: string;
  maintenanceMessage: string;
  requiresRefresh: boolean;
  isQuittable: boolean;
  color: string;
  startDate: string;
  endDate: string;
  appVersion: string;
};

const Footer = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const connexion = useSelector((state: RootState) => state.session.connexion);
  const language = useSelector((state: RootState) => state.views.language);
  const dashboards = useSelector((state: RootState) => state.dashboards.dashboards);
  const streams = useSelector((state: RootState) => state.streams);
  const settings = useSelector((state: RootState) => state.settings.settings);
  const topviews = useSelector((state: RootState) => state.topviews.topviews);
  const users = useSelector((state: RootState) => state.users.users);

  const clientName = settings?.googleAnalytics?.clientName;
  const { version } = pkg;

  const [maintenance, setMaintenance] = useState<MaintenanceObject | null>(null);
  const [reloadTimeoutId, setReloadTimeoutId] = useState<NodeJS.Timeout>();
  const [hideMaintenanceTimeoutId, setHideMaintenanceTimeoutId] = useState<NodeJS.Timeout>();

  const [date, setDate] = useState(new Date());
  const [isFooterCollapsed, setIsFooterCollapsed] = useState(false);

  const handleResize = () => setIsFooterCollapsed(window.innerWidth < MIN_WIDTH_FULL_FOOTER);

  useEffect(() => {
    let immediateFetchTimeoutId: ReturnType<typeof setTimeout> | null = null;

    const fetchMaintenance = async () => {
      const response = await getMaintenance(clientName);
      const newMaintenance = response.maintenance;
      if (!newMaintenance) {
        return;
      }
      const now = new Date();
      const in15Minutes = new Date(now.getTime() + 15 * 60 * 1000);
      const startDate = new Date(newMaintenance.startDate);
      const endDate = new Date(newMaintenance.endDate);

      // If maintenance is an update and the app version is the same, no update is needed
      if (newMaintenance.type === 'update' && newMaintenance?.appVersion === version) {
        setMaintenance(null);
        return;
      }

      // If maintenance is scheduled to start in the next 15 minutes, set a timeout to fetch it again when it starts
      if (startDate >= now && startDate <= in15Minutes) {
        const timeUntilStart = startDate.getTime() - now.getTime();
        if (immediateFetchTimeoutId) {
          clearTimeout(immediateFetchTimeoutId);
        }
        immediateFetchTimeoutId = setTimeout(fetchMaintenance, timeUntilStart);
        return;
      }

      // If maintenance is ongoing, set it
      if (startDate <= now && endDate >= now) {
        // If the maintenance is different from the current one, set it
        if (!deepEqual(newMaintenance, maintenance, { strict: true })) {
          const timeUntilEnd = endDate.getTime() - now.getTime();
          const userHasQuit = localStorage.getItem(`userHasQuitMaintenance-${newMaintenance.id}`);
          if (!userHasQuit) {
            setMaintenance(newMaintenance);
          }
          // Clear the timeout for page refresh if it's already set
          if (reloadTimeoutId) {
            clearTimeout(reloadTimeoutId);
          }
          // Clear the timeout for hiding maintenance message if it's already set
          if (hideMaintenanceTimeoutId) {
            clearTimeout(hideMaintenanceTimeoutId);
          }
          // If maintenance is an update and requires a refresh, set a timeout to reload the page when it ends
          if (newMaintenance.type === 'update' && newMaintenance.requiresRefresh) {
            const newReloadTimeout = setTimeout(() => window.location.reload(), timeUntilEnd);
            setReloadTimeoutId(newReloadTimeout);
          } else {
            const newHideMaintenanceTimeout = setTimeout(() => {
              setMaintenance(null);
            }, timeUntilEnd + 10000);
            setHideMaintenanceTimeoutId(newHideMaintenanceTimeout);
          }
        }
      }
    };

    fetchMaintenance();
    const intervalId = setInterval(fetchMaintenance, 15 * 60 * 1000);

    return () => {
      if (immediateFetchTimeoutId) {
        clearTimeout(immediateFetchTimeoutId);
      }
      clearTimeout(reloadTimeoutId);
      clearTimeout(hideMaintenanceTimeoutId);
      clearInterval(intervalId);
    };
  }, []);

  useInterval(() => {
    setDate(new Date());
  }, 1000);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const formatDateAndTime = dateString => {
    const maintenanceDate = new Date(dateString);
    const year = maintenanceDate.getFullYear();
    const month = (maintenanceDate.getMonth() + 1).toString().padStart(2, '0');
    const day = maintenanceDate.getDate().toString().padStart(2, '0');
    const hours = maintenanceDate.getHours().toString().padStart(2, '0');
    const minutes = maintenanceDate.getMinutes().toString().padStart(2, '0');
    return `${year}-${month}-${day} ${t('at')} ${hours}:${minutes}`;
  };

  const atWord = language === 'fr' ? 'au' : 'to';

  const getMaintenanceText = (maintenanceObj: MaintenanceObject) => {
    if (maintenanceObj.maintenanceMessage) {
      return maintenanceObj.maintenanceMessage;
    }

    switch (maintenanceObj.type) {
      case 'maintenance':
        return `${t('maintenanceScheduledFrom')} ${formatDateAndTime(maintenanceObj.startDate)} ${atWord} ${formatDateAndTime(maintenanceObj.endDate)}`;
      case 'update':
        return maintenanceObj.requiresRefresh ? `${t('updateAvailable')}. ${t('pageWillRefreshAt')} ${formatDateAndTime(maintenanceObj.endDate)}.` : t('updateAvailable');
      case 'bug':
        return t('bugOngoing');
      default:
        return maintenanceObj.type;
    }
  };

  const getFooter = () => {
    const isUserRedirect = connexion.infos?.id === 'Redirected';
    const footerStyle = maintenance ? { backgroundColor: `${maintenance.color}` } : {};

    return (
      <div className="footer" style={footerStyle}>
        {maintenance ? (
          <div className="maintenance">
            <span className="time" style={{ color: invertColor(maintenance.color), lineHeight: '0' }}>{dateToHHmm(date)}</span>
            <div className="maintenanceWarning">
              <FontAwesome icon="exclamation-triangle" style={{ color: invertColor(maintenance.color), fontSize: '20px' }} />
              <span className="maintenanceText" style={{ color: invertColor(maintenance.color) }}>{getMaintenanceText(maintenance)}</span>
            </div>
            {maintenance.isQuittable && (
              <FontAwesome
                icon="times"
                className="closeMaintenance"
                onClick={() => {
                  setMaintenance(null);
                  localStorage.setItem(`userHasQuitMaintenance-${maintenance.id}`, 'true');
                }}
                style={{ color: invertColor(maintenance.color) }}
              />
            )}
          </div>
        ) : (
          <>
            <div className={`${isFooterCollapsed ? 'smallInline' : 'inline left'}`}>
              <span className="time">
                {dateToHHmm(date)}
              </span>
            </div>
            <div
              className={`${isFooterCollapsed ? 'bigInline' : 'inline right'}`}

            >
              {
                !isUserRedirect ? (
                  <Link className="versionNumber" key="changelog" to={directLinks.changeLog()}>
                    v1.25.0
                  </Link>
                )
                  : <span className="versionNumber">v1.25.0</span>
              }
              <Link
                className="versionNumber"
                to={{ pathname: websiteOptions[language] }}
                target="_blank"
                data-testid="footer-poweredBy"
              >
                {isFooterCollapsed ? '' : t('poweredBy')}
                <span className="ii"> Intelligence Industrielle</span>
              </Link>
            </div>
          </>
        )}
      </div>
    );
  };

  return (
    <ResourcesHandler
      resources={[dashboards, streams, topviews, users]}
      resourceFetchers={[
        () => dispatch(reduxOperations.dashboards.fetchDashboards() as any),
        () => dispatch(reduxOperations.streams.fetchStreams() as any),
        () => dispatch(reduxOperations.settings.fetchSettings() as any),
        () => dispatch(reduxOperations.topviews.fetchTopviews() as any),
        () => dispatch(reduxOperations.users.fetchUsers() as any),
      ]}
      getContents={getFooter}
    />
  );
};

export default Footer;
