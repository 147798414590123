import { tileWidgetTypes } from '../types';

function getTileWidgetIcon(type) {
  switch (type) {
    case tileWidgetTypes.AREACHART:
      return 'area-chart';
    case tileWidgetTypes.AUDIO:
      return 'volume-up';
    case tileWidgetTypes.BAR_CHART:
      return 'bar-chart';
    case tileWidgetTypes.BUTTON:
      return 'button';
    case tileWidgetTypes.CIRCULAR:
      return 'circle-notch';
    case tileWidgetTypes.DATA_ENTRY:
      return 'database';
    case tileWidgetTypes.GAUGE:
      return 'tachometer';
    case tileWidgetTypes.INFO:
      return 'info-circle';
    case tileWidgetTypes.MACHINE_STATE:
      return 'ii-machine-state';
    case tileWidgetTypes.MEDIA:
      return 'ii-media-icon';
    case tileWidgetTypes.PARETOCHART:
      return 'ii-progressBar-icon';
    case tileWidgetTypes.PROGRESS_BAR:
      return 'ii-progressBar-icon';
    case tileWidgetTypes.SCORECARD:
      return 'ii-variable';
    case tileWidgetTypes.STOPWATCH:
      return 'ii-stopwatch';
    case tileWidgetTypes.TIMELINE:
      return 'ii-timeline-icon';
    case tileWidgetTypes.TEXT:
      return 'font';
    default:
      return null;
  }
}

export { getTileWidgetIcon };
