const tileWidgetTypes = {
  // alphabetical order
  AREACHART: 'areaChart',
  AUDIO: 'audio',
  BAR_CHART: 'barChart',
  BUTTON: 'button',
  CIRCULAR: 'circularChart',
  DATA_ENTRY: 'dataEntry',
  GAUGE: 'gauge',
  INFO: 'info',
  MACHINE_STATE: 'machineState',
  MEDIA: 'media',
  PARETOCHART: 'paretoChart',
  PROGRESS_BAR: 'progressBar',
  SCORECARD: 'scorecard',
  STOPWATCH: 'stopwatch',
  TEXT: 'text',
  TIMELINE: 'timeline',
};
Object.freeze(tileWidgetTypes);

const widgetTypes = {
  // alphabetical order
  BUTTON: tileWidgetTypes.BUTTON,
  CIRCULAR: tileWidgetTypes.CIRCULAR,
  DATA_ENTRY: tileWidgetTypes.DATA_ENTRY,
  GAUGE: tileWidgetTypes.GAUGE,
  INFO: tileWidgetTypes.INFO,
  MACHINE_STATE: tileWidgetTypes.MACHINE_STATE,
  MEDIA: tileWidgetTypes.MEDIA,
  SCORECARD: tileWidgetTypes.SCORECARD,
  STOPWATCH: tileWidgetTypes.STOPWATCH,
  TEXT: tileWidgetTypes.TEXT,
};
Object.freeze(widgetTypes);

const ruleTypes = {
  // alphabetical order
  COLOR: 'color',
  HIDE: 'hide',
  MEDIA: 'media',
  TRIGGER: 'trigger',
  TEXT: 'text',
};
Object.freeze(ruleTypes);

export {
  tileWidgetTypes,
  widgetTypes,
  ruleTypes,
};
