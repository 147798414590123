import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { DefaultSubmitButton } from '~UI';
import { getTitle } from '~utils';
import { getTitleFontSize } from '~utils/responsiveValue';
import API from '~services/endpoints';
import JsxWidget from './BaseWidgets/JsxWidget';

const DataEntryWidget = ({
  backgroundColor, h, w, x, y, widget,
}) => {
  const { t } = useTranslation();
  const streams = useSelector(state => state.streams);
  const variables = useSelector(state => state.variables);
  const featureToggles = useSelector(state => state.featureToggles.featureToggles);
  const language = useSelector(state => state.views.language);

  const [currentValue, setCurrentValue] = useState('');
  const [isBool, setIsBool] = useState(undefined);
  const [hasUnmounted, setHasUnmounted] = useState(false);

  const title = featureToggles.features?.tileTranslations ? getTitle(widget, language) : widget?.title || '';

  useEffect(() => {
    const properties = [].concat(...streams.map(s => s.properties));
    const all = [...properties, ...variables];
    const value = all.find(elem => elem.id === widget.valueId);

    if (value) {
      API.getValues(widget.valueId, {}, 1).then(res => {
        if (!hasUnmounted) {
          const valueRes = res.values.length ? res.values[0].value : undefined;
          setIsBool(typeof valueRes === 'boolean');
        }
      });
    }

    return () => {
      setHasUnmounted(true);
    };
  }, []);

  const sendData = () => {
    const { valueId: id } = widget;
    const properties = [].concat(...streams.map(s => s.properties));
    const all = [...properties, ...variables];
    const value = all.find(elem => elem.id === id);

    if (currentValue !== '' && id && value) {
      API.updateValue(id, {
        name: value.variable,
        value: currentValue,
      });
    }

    setCurrentValue('');
  };

  const properties = [].concat(...streams.map(s => s.properties));
  const all = [...properties, ...variables];
  const value = all.find(elem => elem.id === widget.valueId);

  if (!value) {
    return (
      <div
        style={{ fontSize: 25, color: 'white', textAlign: 'center' }}
        data-testid="dataEntryTile-valueDoesNotExistDiv"
      >
        {t('valueDoesNotExist')}
      </div>
    );
  }

  const style = {
    fontSize: getTitleFontSize(h, w, t('ValueToSubmit')),
  };

  return (
    <JsxWidget
      dimension={{ x, y, w, h }}
      backgroundColor={backgroundColor}
      shape={widget.format.shape}
      title={title}
      titleSize={widget.titleSize}
    >
      <div className="DataEntry">
        <div className="block">
          {isBool
            ? (
              <div
                className="innerBlock undraggable"
                data-testid="dataEntryTile-booleanButtonsDiv"
              >
                <DefaultSubmitButton
                  className={currentValue ? 'active' : ''}
                  label="1"
                  onClick={() => setCurrentValue(true)}
                  style={style}
                  isActive
                />
                <DefaultSubmitButton
                  className={currentValue === false ? 'active' : ''}
                  label="0"
                  onClick={() => setCurrentValue(false)}
                  style={style}
                  isActive
                />
              </div>
            ) : (
              <input
                type="number"
                step="0.01"
                value={currentValue}
                className="innerBlock undraggable"
                placeholder={t('ValueToSubmit')}
                onChange={e => setCurrentValue(e.target.value !== '' ? Number(e.target.value) : '')}
                style={style}
                data-testid="dataEntryTile-input"
              />
            )}
        </div>
        <div className="block">
          <DefaultSubmitButton
            label={t('submit')}
            onClick={sendData}
            style={style}
            isActive={isBool !== undefined && currentValue !== ''}
          />
        </div>
      </div>
    </JsxWidget>
  );
};

DataEntryWidget.propTypes = {
  backgroundColor: PropTypes.string.isRequired,
  widget: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
    titleSize: PropTypes.number,
    x: PropTypes.number,
    y: PropTypes.number,
    w: PropTypes.number,
    h: PropTypes.number,
    valueId: PropTypes.string,
    format: PropTypes.shape({
      type: PropTypes.string.isRequired,
      data: PropTypes.shape({}),
      shape: PropTypes.string,
    }),
    linksTo: PropTypes.string,
  }).isRequired,
  h: PropTypes.number.isRequired,
  w: PropTypes.number.isRequired,
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
};

export default DataEntryWidget;
