import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { DefaultTable, PageTemplate } from '~components/Pages';
import {
  DefaultThemedButton, ModalHandler, ResourcesHandler, SquaredAddButton,
} from '~UI';
import { streamTypes } from '~utils/types';
import { reduxOperations } from '~services';
import { getSocket } from '~services/socket';
import API from '~services/endpoints';
import { showError } from '~utils/toast';
import { round } from '~utils/math';
import { streamsFeature } from '~utils/featureToggles';
import { getLocalStorageObject, getLocalStorageSetter } from '~utils/localStorage';
import {
  variableIsCriticallyUsed,
  getCriticallyUsedErrorMessage,
  variableUsedByActionMessage,
} from '~utils/isVariableUsed';
import Inputs3D from './Inputs3D/Inputs3D';
import InputCRUDPage from './InputCRUDPage';

const putStorage = getLocalStorageSetter('K2_inputs');

const InputsPage = () => {
  const socket = getSocket();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { selectedStream } = useParams();

  const variables = useSelector(state => state.variables);
  const streams = useSelector(state => state.streams);
  const actions = useSelector(state => state.actions);
  const triggers = useSelector(state => state.triggers);
  const machines = useSelector(state => state.machines);
  const featureToggles = useSelector(state => state.featureToggles.featureToggles);

  const storage = getLocalStorageObject('K2_inputs');
  const [mode, setMode] = useState(storage.mode || 'list');
  const [modifiedItem, setModifiedItem] = useState('');
  const [showPopupForm, setShowPopupForm] = useState(false);
  const [properties, setProperties] = useState([]);
  const propertiesRef = useRef(properties);
  const pendingUpdates = useRef({});
  const isUpdating = useRef(false);

  const handleSocketData = socketData => {
    if (!propertiesRef.current.find(property => property.id === socketData.id)) {
      return;
    }

    if (typeof socketData.value === 'boolean') {
      pendingUpdates.current[socketData.id] = socketData.value ? 'true' : 'false';
    } else if (typeof socketData.value === 'string') {
      pendingUpdates.current[socketData.id] = `"${socketData.value}"`;
    } else {
      pendingUpdates.current[socketData.id] = socketData.value !== null ? (round(socketData.value, 4) || 0) : 'NaN';
    }

    if (isUpdating.current) {
      return;
    }

    isUpdating.current = true;
    setTimeout(() => {
      setProperties(prevState => {
        const updatedProperties = prevState.map(property => {
          const update = pendingUpdates.current[property.id];
          return update !== undefined ? { ...property, value: update } : property;
        });
        pendingUpdates.current = {};
        isUpdating.current = false;

        return updatedProperties;
      });
    }, 500);
  };

  const fetchPropertiesValues = async propertiesToFetch => {
    const { values } = await API.getMultiplesValues(propertiesToFetch.map(property => property.id), {}, 1) || {};
    return propertiesToFetch.map(property => {
      const value = values?.find(val => val.valueId === property.id)?.value;
      if (typeof value === 'boolean') {
        return {
          ...property,
          value: value ? 'true' : 'false',
        };
      }
      if (typeof value === 'string') {
        return {
          ...property,
          value: `"${value}"`,
        };
      }
      return {
        ...property,
        value: value !== null ? (round(value, 4) || 0) : 'NaN',
      };
    });
  };

  useEffect(() => {
    async function fetchValues(propertiesToFetch) {
      const fetchedProperties = await fetchPropertiesValues(propertiesToFetch);
      setProperties(fetchedProperties);
    }

    const streamId = selectedStream;
    const stream = streams.find(s => s.id === streamId);
    fetchValues(stream.properties);
    socket?.on('value', handleSocketData);
    return () => {
      socket?.removeListener('value', handleSocketData);
    };
  }, [socket, selectedStream, streams]);

  useEffect(() => {
    propertiesRef.current = properties;
  }, [properties]);

  const handleOnClick = id => {
    setModifiedItem(id);
    setShowPopupForm(true);
  };

  const handleDelete = async (streamId, id) => {
    const message = getCriticallyUsedErrorMessage(id, actions, triggers, variables);
    if (message) {
      showError(message);
      return;
    }

    return dispatch(reduxOperations.streams.deleteStreamProperty(streamId, id));
  };

  const getDeleteType = id => {
    // Do not allow delete, variable is critical
    const isCritical = getCriticallyUsedErrorMessage(id, actions, triggers, variables);
    if (isCritical) {
      return { type: 'none', usedBy: isCritical };
    }
    // Allow delete with a warning that it is used but not critically
    const isUsed = variableUsedByActionMessage(id, actions);
    if (isUsed) {
      return { type: 'warning', usedBy: isUsed };
    }
    return { type: 'delete', usedBy: null };
  };

  const toggle3DView = () => setMode(prevState => {
    const newMode = prevState === '3D' ? 'list' : '3D';
    putStorage('mode', newMode);
    return newMode;
  });

  const getPage = () => {
    const streamId = selectedStream;
    const stream = streams.find(s => s.id === streamId);

    const isMode3DAllowed = (stream.type === streamTypes.SENSOR && featureToggles.buttons.view3D);
    const isMode3D = (isMode3DAllowed && mode === '3D');

    const isNotUsedStyleCondition = {
      style: { opacity: '0.6' },
      condition: input => !variableIsCriticallyUsed(input.id, actions, triggers, variables),
    };

    let content = (
      <div className="InputsPageList">
        {
          showPopupForm && streamsFeature.isUserAllowedToConfigure() && (
            <InputCRUDPage
              show={showPopupForm}
              modifiedItemId={modifiedItem}
              streamId={stream.id}
              onHide={() => setShowPopupForm(false)}
            />
          )
        }
        <DefaultTable
          columnNames={[
            { name: t('name') },
            { name: t('variable') },
            { name: t('normalize') },
            { name: t('realTimeValue') },
            { name: t('units') },
            ...(streamsFeature.isUserAllowedToConfigure() ? [{ name: t('History'), info: t('historyDescription') }] : []),
          ]}
          const
          entriesProperties={[
            'name',
            'variable',
            'lerp',
            'value',
            'units',
            ...(streamsFeature.isUserAllowedToConfigure() ? ['toggle'] : []),
          ]}
          entriesStyle={[
            { property: 'name', ...isNotUsedStyleCondition },
            { property: 'variable', ...isNotUsedStyleCondition },
            { property: 'lerp', ...isNotUsedStyleCondition },
            { property: 'value', ...isNotUsedStyleCondition },
            { property: 'units', ...isNotUsedStyleCondition },
          ]}
          entries={properties}
          editForm={streamsFeature.isUserAllowedToConfigure() ? InputCRUDPage : null}
          editFormProps={{
            streamId: stream.id,
          }}
          deleteFunction={streamsFeature.isUserAllowedToConfigure() ? id => handleDelete(streamId, id) : null}
          toggleOnChange={streamsFeature.isUserAllowedToConfigure() ? (id, value) => dispatch(
            reduxOperations.streams.updateStreamProperty(streamId, id, { isEnabled: value }),
          ) : () => {}}
          deleteType={streamsFeature.isUserAllowedToConfigure() ? id => getDeleteType(id) : null}
          onClickEvent={streamsFeature.isUserAllowedToConfigure() ? id => handleOnClick(id) : null}
        />
      </div>
    );
    if (isMode3D) {
      content = (
        <Inputs3D
          streamId={streamId}
          properties={stream.properties}
          isUserAllowedToConfigure={streamsFeature.isUserAllowedToConfigure()}
        />
      );
    }

    return (
      <PageTemplate
        sidebar
        title={stream.name}
        rightButtons={(
          <>
            {
              isMode3DAllowed && (
                <DefaultThemedButton
                  onClick={toggle3DView}
                  key="btn3D"
                  content={t('threeDView')}
                  isActive={isMode3D}
                />
              )
            }
            {streamsFeature.isUserAllowedToConfigure() && (
              <ModalHandler
                Trigger={{
                  Component: SquaredAddButton,
                  props: {
                    style: { height: '30px' },
                  },
                }}
                Modal={{
                  Component: InputCRUDPage,
                  props: { streamId: stream.id },
                }}
              />
            )}
          </>
        )}
      >
        {content}
      </PageTemplate>
    );
  };

  return (
    <ResourcesHandler
      resources={[variables, streams, actions, triggers, machines]}
      resourceFetchers={[
        () => dispatch(reduxOperations.variables.fetchVariables()),
        () => dispatch(reduxOperations.streams.fetchStreams()),
        () => dispatch(reduxOperations.actions.fetchActions()),
        () => dispatch(reduxOperations.triggers.fetchTriggers()),
        () => dispatch(reduxOperations.machines.fetchMachines()),
      ]}
      getContents={getPage}
    />
  );
};

export default InputsPage;
