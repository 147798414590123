import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { RootState } from '~services/store';
import { stringWithBreakLines } from '~utils/stringWithBreakLines';
import { PageTemplate } from '~components/Pages';
import './ErrorPage.scss';

interface ErrorPageProps {
  errorNumber: number;
  errorDefinition: { en: string; es: string; fr: string; };
  errorDescription?: { en: string; es: string; fr: string; };
}

const ErrorPage = ({
  errorNumber,
  errorDefinition,
  errorDescription,
}: ErrorPageProps) => {
  const { t } = useTranslation();
  const language = useSelector((state: RootState) => state.views.language);

  const definitionText = errorDefinition instanceof String
    ? errorDefinition
    : (errorDefinition && errorDefinition[language]);
  const descriptionText = errorDescription instanceof String
    ? errorDescription
    : stringWithBreakLines(t('errorDescription'), 2);
  return (
    <PageTemplate>
      <div className="ErrorPage">
        <h1>
          {`${t('error')} ${errorNumber}`}
        </h1>
        <h3>{ definitionText }</h3>
        <br />
        <h4>{ descriptionText }</h4>
      </div>
    </PageTemplate>
  );
};

ErrorPage.propTypes = {
  errorNumber: PropTypes.number.isRequired,
  errorDefinition: PropTypes.oneOfType([
    PropTypes.shape({}),
    PropTypes.string,
  ]).isRequired,
  errorDescription: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
  ]),
};

export default ErrorPage;
