import { FormEmpty } from '~components/forms/FormEmpty';
// alphabetical order
import FormTileAreaChart from './FormTileAreaChart';
import { FormTileAudio } from './FormTileAudio';
import FormTileBarChart from './FormTileBarChart';
import FormTileButton from './FormTileButton';
import FormTileDataEntry from './FormTileDataEntry';
import FormTileGauge from './FormTileGauge';
import FormTileInfo from './FormTileInfo';
import FormTileMachineState from './FormTileMachineState';
import FormTileMedia from './FormTileMedia';
import FormTileParetoChart from './FormTileParetoChart';
import FormTileProgressBar from './FormTileProgressBar';
import FormTileScorecard from './FormTileScorecard';
import FormTileStopwatch from './FormTileStopwatch';
import FormTileText from './FormTileText';
import FormTileTimeline from './FormTileTimeline';

export default {
  empty: FormEmpty,
  // alphabetical order
  areaChart: FormTileAreaChart,
  audio: FormTileAudio,
  barChart: FormTileBarChart,
  button: FormTileButton,
  circularChart: FormTileGauge,
  dataEntry: FormTileDataEntry,
  gauge: FormTileGauge,
  info: FormTileInfo,
  machineState: FormTileMachineState,
  media: FormTileMedia,
  paretoChart: FormTileParetoChart,
  progressBar: FormTileProgressBar,
  scorecard: FormTileScorecard,
  stopwatch: FormTileStopwatch,
  text: FormTileText,
  timeline: FormTileTimeline,
};
