import { reduxOperations as actions } from './actions';
import { reduxOperations as audios } from './audios';
import { reduxOperations as clientMetrics } from './clientMetrics';
import { reduxOperations as commands } from './commands';
import { reduxOperations as dashboards } from './dashboards';
import { reduxOperations as featureToggles } from './featureToggles';
import { reduxOperations as events } from './events';
import { reduxOperations as folders } from './folders';
import { reduxOperations as forms } from './forms';
import { reduxOperations as images } from './images';
import { reduxOperations as linkRedirection } from './linkRedirection';
import { reduxOperations as logs } from './logs';
import { reduxOperations as machines } from './machines';
import { reduxOperations as operations } from './operations';
import { reduxOperations as operators } from './operators';
import { reduxOperations as redirectHistory } from './redirectHistory';
import { reduxOperations as reports } from './reports';
import { reduxOperations as session } from './session';
import { reduxOperations as settings } from './settings';
import { reduxOperations as shifts } from './shifts';
import { reduxOperations as stopwatches } from './stopwatches';
import { reduxOperations as streams } from './streams';
import { reduxOperations as tags } from './tags';
import { reduxOperations as topviews } from './topviews';
import { reduxOperations as triggers } from './triggers';
import { reduxOperations as users } from './users';
import { reduxOperations as variables } from './variables';
import { reduxOperations as views } from './views';

export default {
  actions,
  audios,
  clientMetrics,
  commands,
  dashboards,
  events,
  featureToggles,
  folders,
  forms,
  images,
  linkRedirection,
  logs,
  machines,
  operations,
  operators,
  redirectHistory,
  reports,
  session,
  settings,
  shifts,
  stopwatches,
  streams,
  tags,
  topviews,
  triggers,
  users,
  variables,
  views,
};
