import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { reduxOperations } from '~services';
import { CancelButton, DefaultModal, SubmitButton, FileUpload } from '~UI/index';
import { showUpload } from '~utils/toast';

const ImagePopupForm = ({ onHide, show, addImage }) => {
  const { t } = useTranslation();
  const [file, setFile] = useState();

  const handleSubmit = e => {
    e.preventDefault();
    showUpload(
      addImage(file),
      t('uploadingImage'),
      t('showSuccessAdded'),
    ).then(() => {
      onHide();
      setFile(null);
    });
  };

  return (
    <DefaultModal
      show={show}
      title={t('uploadImage')}
      closePopup={onHide}
      children={(
        <form
          id="Image__form"
          onSubmit={handleSubmit}
        >
          <FileUpload type="popupForm" onFileSelect={setFile} />
          {file && (
            <div className="fileName">
              <div>
                {t('fileAdded')}
                :
              </div>
              -
              {' '}
              {file.name}
            </div>
          )}

          <div className="buttonsHolder" style={{ textAlign: 'right' }}>
            <CancelButton onClick={onHide} />
            <SubmitButton label={t('create')} />
          </div>
        </form>
      )}
    />
  );
};

ImagePopupForm.propTypes = {
  onHide: PropTypes.func.isRequired,
  addImage: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    addImage: reduxOperations.images.addImage,
  }, dispatch);
}

export default connect(null, mapDispatchToProps)(ImagePopupForm);
