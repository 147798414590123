import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { DateTime } from 'luxon';
import { Input, Select } from '@intelligenceindustrielle/react-ui-components';
import { initializeSelectedUsers } from '~components/SelectionGrid';
import {
  CancelButton, DefaultModal, DeleteButton, MultiSelect, SubmitButton, TagsInput, FileUpload,
} from '~components/UI';
import { Dashboard } from '~services/dashboards/types';
import { RootState } from '~services/store';
import { reduxOperations } from '~services/index';
import { configurationFeature } from '~utils/featureToggles';
import { getFormData } from '~utils/getFormData';
import { showSuccess, showError, showUpload } from '~utils/toast';
import { idsToTags } from '~utils/tags';
import { sortArray } from '~utils/sort';
import { Folder } from '~services/folders/types';

const DashboardPopUpForm = ({ modifiedItemId, show, onHide }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const dashboards = useSelector((state: RootState) => state.dashboards.dashboards) as Dashboard[];
  const folders = useSelector((state: RootState) => state.folders);
  const tags = useSelector((state: RootState) => state.tags.tags);
  const users = useSelector((state: RootState) => state.users.users);
  const images = useSelector((state: RootState) => state.images.images);
  const userId = useSelector((state: RootState) => state.session.connexion.infos.id);

  const dashboard = dashboards?.find(d => d.id === modifiedItemId);

  const [selectedDashboards, setSelectedDashboards] = useState<{
    label: string,
    value: string,
  }[] | undefined>([]);
  const [folder, setFolder] = useState(dashboard?.folderId);
  const [image, setImage] = useState(dashboard?.backgroundURL || '');
  const [type, setType] = useState(dashboard?.type);
  const [dashboardTags, setDashboardTags] = useState(dashboard && tags ? idsToTags(dashboard?.tags, tags) : []);
  const [selectedUsers, setSelectedUsers] = useState<{ value: string }[]>([]);

  const typeOptions = [
    { value: 'Default', name: t('Default') },
    { value: 'Multiple', name: t('carousel') },
  ];

  const initializeSelectedDashboards = () => {
    if (modifiedItemId) {
      if (dashboard?.type === 'Multiple') {
        const currentSelectedDashboards = dashboards?.filter(d => dashboard?.dashboards.includes(d.id))
          .map(d => ({ label: d.name, value: d.id }));
        setSelectedDashboards(currentSelectedDashboards);
      }
    }
  };

  const updateUsersPermissions = (id: string) => {
    const usersWithPermission = selectedUsers.concat([{ value: userId }]);
    const permissionsData = {
      users: usersWithPermission.map(user => user.value),
      content: {
        type: 'Dashboard',
        id,
      },
    };
    if (configurationFeature.isUserAllowedAccessUsers()) {
      reduxOperations.users.updateUsersContentsPermissions(permissionsData)(dispatch).then(() => {
        dispatch(reduxOperations.users.fetchAllUsersPermissions() as any);
      });
    }
  };

  const handleOnHide = () => {
    onHide();
    setSelectedDashboards([]);
    setFolder(dashboard?.folderId);
    setType(dashboard?.type);
    setSelectedUsers([]);
  };

  const handleTypeChange = e => {
    if (e === 'Default') {
      setSelectedDashboards([]);
    }
    setType(e);
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    let formData = getFormData('Dashboard__form');
    if (!formData.name) {
      showError(t('missingName'));
      return;
    }
    formData.dashboards = selectedDashboards?.map(d => d.value);
    formData.tags = dashboardTags;

    if (!formData.tiles && formData.type === 'Default') {
      formData = { ...formData, tiles: [] };
    }

    if (formData.folderId === '-') {
      formData.folderId = '';
    }

    if (modifiedItemId) {
      if (formData.description === undefined) {
        formData.description = '';
      }
      if (formData.backgroundURL === undefined) {
        formData.backgroundURL = '';
      }
      formData = formData.type === 'Default' ? { ...formData, tiles: dashboard?.tiles || [] } : formData;
      reduxOperations.dashboards.updateDashboard(modifiedItemId, formData)(dispatch)
        .then(() => {
          handleOnHide();
          dispatch(reduxOperations.tags.forceFetchTags() as any);
          showSuccess(t('showSuccessUpdated'));
          updateUsersPermissions(modifiedItemId);
        })
        .catch(() => showError(t('invalidFormData')));
    } else {
      reduxOperations.dashboards.addDashboard(formData)(dispatch).then(res => {
        handleOnHide();
        dispatch(reduxOperations.tags.forceFetchTags() as any);
        showSuccess(t('showSuccessCreated'));
        updateUsersPermissions(res.payload.dashboard.id);
      }).catch(() => showError(t('invalidFormData')));
    }
  };

  const handleDelete = async () => {
    reduxOperations.dashboards.deleteDashboard(modifiedItemId)(dispatch);
    handleOnHide();
  };

  const showSelectionDashboard = () => {
    if (type === 'Multiple') {
      const intervalContent = (
        <>
          <div className="inputTitle">{t('dashboardIntervalSeconds')}</div>
          <input
            name="number:interval"
            type="number"
            className="fullwidth"
            defaultValue={dashboard?.interval}
          />
        </>
      );

      const dashboardsFiltered = sortArray('alphabetically', dashboards?.filter(d => d.id !== modifiedItemId && d.type !== 'Multiple'), 'name');

      if (dashboardsFiltered.length === 0) {
        return (
          <>
            {intervalContent}
            {t('noDashboardToSelect')}
          </>
        );
      }

      const dashboardsOptions = dashboardsFiltered.map(d => ({ value: d.id, label: d.name }));
      return (
        <>
          {intervalContent}
          <div className="inputTitle">{t('dashboards')}</div>
          <MultiSelect
            options={dashboardsOptions}
            value={selectedDashboards as object[]}
            onChange={options => setSelectedDashboards(options)}
          />
        </>
      );
    }
    return null;
  };

  useEffect(() => {
    if (modifiedItemId) {
      setSelectedUsers(initializeSelectedUsers('Dashboard', modifiedItemId, users, userId));
      initializeSelectedDashboards();
    }
  }, [modifiedItemId, users, show]);

  useEffect(() => {
    const newDashboard = dashboards?.find(v => v.id === modifiedItemId);

    setDashboardTags(newDashboard && idsToTags(newDashboard.tags, tags));
  }, [show]);

  const usersToDisplay = users ? users.filter(user => user.display && user.id !== userId) : [];
  const usersOptions = usersToDisplay.map(user => ({
    label: user.name,
    value: user.id,
  }));

  const handleFileSelect = selectedFile => {
    showUpload(
      reduxOperations.images.addImage(selectedFile)(dispatch),
      t('uploadingImage'),
      t('showSuccessAdded'),
    )
      .then(res => {
        if (typeof res.payload.data === 'string') {
          setImage(res.payload.data);
        }
      });
  };

  return (
    <DefaultModal
      title={modifiedItemId ? t('editDashboard') : t('createNewDashboard')}
      show={show}
      closePopup={handleOnHide}
    >
      <form
        id="Dashboard__form"
        onSubmit={handleSubmit}
      >
        <div className="inputTitle">{t('name')}</div>
        <Input
          defaultValue={dashboard?.name}
          name="name"
          placeholder={t('valueInput')}
        />

        <div className="inputTitle">{t('description')}</div>
        <Input
          defaultValue={dashboard?.description}
          name="description"
          placeholder={t('valueInput')}
        />

        <div className="inputTitle">{`${t('image')} URL`}</div>
        <div className="fileContainer">
          <Input
            className="fullwidth"
            value={image}
            onChange={setImage}
            name="backgroundURL"
            placeholder={t('valueInput')}
            style={{ marginRight: '10px', flexGrow: 1 }}
          />
          <FileUpload
            onFileSelect={handleFileSelect}
            images={images}
            image={image || ''}
            setImage={setImage}
          />
        </div>

        <div className="inputTitle">{t('type')}</div>
        <Select
          className="fullwidth"
          name="type"
          value={type || 'Default'}
          onChange={e => handleTypeChange(e)}
          options={typeOptions.map(typeOption => ({ label: typeOption.name, value: typeOption.value }))}
        />
        {folders && (
          <>
            <div className="inputTitle">{t('folder')}</div>
            <Select
              className="fullwidth"
              name="folderId"
              value={folder || '-'}
              onChange={setFolder}
              options={[{ label: '-', value: '-' },
                ...sortArray('alphabetically', folders, 'name')?.map((f: Folder) => ({ label: f.name, value: f.id }))]}
            />
          </>
        )}
        {showSelectionDashboard()}
        {
          configurationFeature.isUserAllowedAccessUsers() && (
            <>
              <div className="inputTitle">{t('UserPermissions')}</div>
              <MultiSelect
                options={usersOptions}
                value={selectedUsers}
                onChange={setSelectedUsers}
              />
            </>
          )
        }
        <div className="inputTitle">{t('tags')}</div>
        <TagsInput
          tags={tags}
          currentTags={dashboardTags || []}
          modifyTags={setDashboardTags}
        />
        {modifiedItemId && (
          <div style={{ margin: '1rem 0' }}>
            <div style={{ fontSize: '17px' }}>
              <div
                style={{ margin: '0.7rem 0' }}
                title={dashboard?.createdAt && DateTime.fromMillis(dashboard?.createdAt).toISO()}
              >
                {t('createdBy')}
                &nbsp;
                <b>{users?.find(user => user.id === dashboard?.createdBy)?.name}</b>
                &nbsp;
                {dashboard?.createdAt && DateTime.fromMillis(dashboard.createdAt).toRelative()}
              </div>
              <div
                style={{ margin: '0.7rem 0' }}
                title={dashboard?.modifiedAt && DateTime.fromMillis(dashboard.modifiedAt).toISO()}
              >
                {t('modifiedBy')}
                &nbsp;
                <b>{users?.find(user => user.id === dashboard?.createdBy)?.name}</b>
                &nbsp;
                {dashboard?.modifiedAt && DateTime.fromMillis(dashboard.modifiedAt).toRelative()}
              </div>
            </div>
          </div>
        )}
        <div className="buttonsHolder flexSpaceBetween">
          {
            modifiedItemId ? (
              <DeleteButton handleDelete={handleDelete} askConfirmation />
            ) : <div />
          }
          <div>
            <CancelButton onClick={handleOnHide} />
            <SubmitButton label={modifiedItemId ? t('modify') : t('add')} />
          </div>
        </div>
      </form>
    </DefaultModal>
  );
};

DashboardPopUpForm.propTypes = {
  show: PropTypes.bool,
  modifiedItemId: PropTypes.string,
  onHide: PropTypes.func,
};

DashboardPopUpForm.defaultProps = {
  onHide: () => null,
  show: false,
  modifiedItemId: '',
};

export default DashboardPopUpForm;
