import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Nav, NavItem } from 'react-bootstrap';
import { PageTemplate } from '~components/Pages';
import { ResourcesHandler } from '~components/UI';
import reduxOperations from '~services/reduxOperations';
import { RootState } from '~services/store';
import { configurationFeature } from '~utils/featureToggles';

import { SettingsModificationForm } from '../Settings/SettingsModificationForm';
import ClientMetricsPage from './ClientMetrics';
import RedirectionLinkTable from './RedirectionLinkTable';
import { Page401Unauthorized } from '../../ErrorPages';
import './AdminPage.scss';

const AdminPage = () => {
  const dispatch = useDispatch();

  const dashboards = useSelector((state: RootState) => state.dashboards.dashboards);
  const topviews = useSelector((state: RootState) => state.topviews.topviews);
  const { t } = useTranslation();
  const [navSelected, setNavSelected] = useState('settings');

  const handleTabChange = eventKey => {
    setNavSelected(eventKey);
  };

  const content = () => (
    configurationFeature.isUserAllowedAccessAdmin() ? (
      <PageTemplate
        sidebar
        title={t('admin')}
      >
        <div className="AdminPage">
          <Nav
            activeKey={navSelected}
            bsStyle="tabs"
            onSelect={handleTabChange}
          >
            <NavItem eventKey="settings">
              {t('settings')}
            </NavItem>
            <NavItem eventKey="clientMetrics">
              {t('clientMetrics')}
            </NavItem>
            <NavItem eventKey="redirectionLinks">
              {t('redirectionLinks')}
            </NavItem>
          </Nav>
          {navSelected === 'settings' && (
            <SettingsModificationForm />
          )}
          {navSelected === 'clientMetrics' && (
            <ClientMetricsPage />
          )}
          {navSelected === 'redirectionLinks' && (
            <RedirectionLinkTable />
          )}
        </div>
      </PageTemplate>
    ) : (
      <Page401Unauthorized />
    )
  );

  return (
    <ResourcesHandler
      resources={[dashboards, topviews]}
      resourceFetchers={[
        () => dispatch(reduxOperations.dashboards.fetchDashboards() as any),
        () => dispatch(reduxOperations.topviews.fetchTopviews() as any),
      ]}
      getContents={content}
    />
  );
};

export default AdminPage;
