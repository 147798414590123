import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { DateTime } from 'luxon';
import { useDispatch } from 'react-redux';
import { ClientMetrics as ClientMetricsType } from '~services/clientMetrics/types';
import { showError } from '~utils/toast';
import reduxOperations from '~services/reduxOperations';

const ClientMetrics = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [clientMetrics, setClientMetrics] = useState<ClientMetricsType[]>([]);
  const [lastWeekClientMetrics, setLastWeekClientMetrics] = useState<{triggers: number, actions: number} | null>();
  const [lastMonthClientMetrics, setLastMonthClientMetrics] = useState<{triggers: number, actions: number} | null>();
  const [lastYearClientMetrics, setLastYearClientMetrics] = useState<{triggers: number, actions: number} | null>();

  const oneWeekAgo = DateTime.now().toUTC().minus({ weeks: 1 }).startOf('day')
    .toMillis();
  const oneMonthAgo = DateTime.now().toUTC().minus({ months: 1 }).startOf('day')
    .toMillis();
  const oneYearAgo = DateTime.now().toUTC().minus({ years: 1 }).startOf('day')
    .toMillis();

  const fetchClientMetrics = async () => {
    const filter = { timestamp: { $gte: oneYearAgo } };
    dispatch(reduxOperations.clientMetrics.fetchClientMetrics(filter) as any)
      .then(res => {
        setClientMetrics(res.payload.clientMetrics);
      })
      .catch(err => showError(err.message));
  };

  useEffect(() => {
    fetchClientMetrics();
  }, []);

  useEffect(() => {
    const lastWeekMetrics = clientMetrics.filter(metric => metric.timestamp >= oneWeekAgo);
    setLastWeekClientMetrics(lastWeekMetrics.reduce((acc, metric) => {
      acc.triggers += metric.triggers;
      acc.actions += metric.actions;
      return acc;
    }, { triggers: 0, actions: 0 }));

    const lastMonthMetrics = clientMetrics.filter(metric => metric.timestamp >= oneMonthAgo);
    setLastMonthClientMetrics(lastMonthMetrics.reduce((acc, metric) => {
      acc.triggers += metric.triggers;
      acc.actions += metric.actions;
      return acc;
    }, { triggers: 0, actions: 0 }));

    setLastYearClientMetrics(clientMetrics.reduce((acc, metric) => {
      acc.triggers += metric.triggers;
      acc.actions += metric.actions;
      return acc;
    }, { triggers: 0, actions: 0 }));
  }, [clientMetrics]);

  return (
    <div className="clientMetricsContainer">
      <div className="clientMetrics">
        <div className="clientMetric">
          <div className="clientMetricLabel">{`${t('lastWeek')} :`}</div>
          <ul>
            <li className="clientMetricValue">
              {`${t('triggers')} : ${lastWeekClientMetrics?.triggers}`}
            </li>
            <li className="clientMetricValue">
              {`${t('actions')} : ${lastWeekClientMetrics?.actions}`}
            </li>
          </ul>
        </div>
        <div className="clientMetric">
          <div className="clientMetricLabel">{`${t('lastMonth')} :`}</div>
          <ul>
            <li className="clientMetricValue">
              {`${t('triggers')} : ${lastMonthClientMetrics?.triggers}`}
            </li>
            <li className="clientMetricValue">
              {`${t('actions')} : ${lastMonthClientMetrics?.actions}`}
            </li>
          </ul>
        </div>
        <div className="clientMetric">
          <div className="clientMetricLabel">{`${t('lastYear')} :`}</div>
          <ul>
            <li className="clientMetricValue">
              {`${t('triggers')} : ${lastYearClientMetrics?.triggers}`}
            </li>
            <li className="clientMetricValue">
              {`${t('actions')} : ${lastYearClientMetrics?.actions}`}
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default ClientMetrics;
