import React from 'react';
import PropTypes from 'prop-types';
import CircleWidget from './CircleWidget';
import RectWidget from './RectWidget';

const shapeWidgets = {
  circle: CircleWidget,
  rect: RectWidget,
};

const SvgWidget = ({
  dimension, backgroundColor, children = null, shape, title, titlesize,
}) => {
  const ShapeWidget = shapeWidgets[shape] || RectWidget;
  return (
    <ShapeWidget
      dimension={dimension}
      backgroundColor={backgroundColor}
      title={title}
      titlesize={titlesize}
    >
      {children}
    </ShapeWidget>
  );
};

SvgWidget.propTypes = {
  dimension: PropTypes.shape({
    x: PropTypes.number.isRequired,
    y: PropTypes.number.isRequired,
    w: PropTypes.number.isRequired,
    h: PropTypes.number.isRequired,
  }).isRequired,
  backgroundColor: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  shape: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  titlesize: PropTypes.number.isRequired,
};

export default SvgWidget;
