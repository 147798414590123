import types from './types';

const getFeatureTogglesRequest = () => ({
  type: types.GET_FEATURE_TOGGLES_REQUEST,
});

const getFeatureTogglesResponse = res => ({
  type: types.GET_FEATURE_TOGGLES_RESPONSE,
  payload: res,
});

export {
  getFeatureTogglesRequest,
  getFeatureTogglesResponse,
};
