import { ActionType } from '~services/actions/types';

const getActionIcon = actionType => {
  switch (actionType) {
    case ActionType.EMAIL:
      return 'envelope';
    case ActionType.EVENT:
      return 'ii-event';
    case ActionType.MACHINE_PARAM:
      return 'hashtag';
    case ActionType.MACHINE_STATE:
      return 'ii-machine-state';
    case ActionType.MQTT:
      return 'rss';
    case ActionType.PART:
      return 'cog';
    case ActionType.PERFORMANCE:
      return 'chart-line';
    case ActionType.PUSH_NOTIFICATION:
      return 'bell';
    case ActionType.SMS:
      return 'phone';
    case ActionType.STOPWATCH:
      return 'ii-stopwatch';
    case ActionType.VARIABLE:
      return 'ii-variable';
    case '+':
      return 'plus';
    default:
      return null;
  }
};

export default getActionIcon;
