import { FormEmpty } from './FormEmpty';
import FormAreaChart from './FormAreaChart';
import { FormAudio } from './FormAudio';
import FormButton from './FormButton';
import { FormGauge } from './FormGauge';
import FormInfo from './FormInfo';
import { FormMachineState } from './FormMachineState';
import { FormMedia } from './FormMedia/FormMedia';
import { FormParetoChart } from './FormParetoChart';
import FormScorecard from './FormScorecard';
import { FormStopwatch } from './FormStopwatch';
import { FormText } from './FormText';
import { FormTimeline } from './FormTimeline';
import { FormDataEntry } from './FormDataEntry';

export default {
  empty: FormEmpty,
  areaChart: FormAreaChart,
  audio: FormAudio,
  button: FormButton,
  circularChart: FormGauge,
  gauge: FormGauge,
  info: FormInfo,
  machineState: FormMachineState,
  media: FormMedia,
  paretoChart: FormParetoChart,
  scorecard: FormScorecard,
  stopwatch: FormStopwatch,
  text: FormText,
  dataEntry: FormDataEntry,
};

export {
  FormAreaChart,
  FormAudio,
  FormButton,
  FormGauge,
  FormInfo,
  FormMachineState,
  FormMedia,
  FormParetoChart,
  FormScorecard,
  FormStopwatch,
  FormText,
  FormTimeline,
  FormDataEntry,
};
