import React from 'react';
import { useTranslation } from 'react-i18next';
import ErrorPage from './ErrorPage';

const Page401Unauthorized = () => {
  const { t } = useTranslation();
  return (
    <ErrorPage
      errorNumber={401}
      errorDefinition={t('unauthorized')}
    />
  );
};

export default Page401Unauthorized;
