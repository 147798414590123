import React from 'react';
import PropTypes from 'prop-types';
import FontAwesome from 'react-fontawesome';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition, library } from '@fortawesome/fontawesome-svg-core';
import {
  // please keep this in alphaetical order
  faArrowAltCircleLeft, faArrowLeft, faArrowRight,
  faBan, faBarsStaggered, faBug,
  faChartLine, faChartPie, faCheckDouble, faCircleNotch, faClock, faClone, faCommentAlt, faCompress, faConciergeBell,
  faEdit, faEquals, faExclamationCircle, faExpand,
  faHome,
  faIndustry,
  faInfoCircle,
  faKeyboard,
  faListCheck,
  faMale, faMinus,
  faPlug, faPlus, faPowerOff,
  faQuestion,
  faRuler, faRulerCombined, faRulerHorizontal, faRulerVertical, faRunning,
  faSave, faShapes, faSignOutAlt, faSortNumericDown, faStopwatch, faStream, faSubscript,
  faToggleOn, faTrafficLight,
  faUser,
} from '@fortawesome/free-solid-svg-icons/index';
import '~styles/css/FontAwesome.css';
import SvgIcon from './SvgIcon';

library.add(
  // A
  faArrowAltCircleLeft as IconDefinition,
  faArrowLeft as IconDefinition,
  faArrowRight as IconDefinition,
  // B
  faBan as IconDefinition,
  faBarsStaggered as IconDefinition,
  faBug as IconDefinition,
  // C
  faChartLine as IconDefinition,
  faChartPie as IconDefinition,
  faCheckDouble as IconDefinition,
  faCircleNotch as IconDefinition,
  faClock as IconDefinition,
  faClone as IconDefinition,
  faCommentAlt as IconDefinition,
  faCompress as IconDefinition,
  faConciergeBell as IconDefinition,
  // E
  faEdit as IconDefinition,
  faEquals as IconDefinition,
  faExclamationCircle as IconDefinition,
  faExpand as IconDefinition,
  // H
  faHome as IconDefinition,
  // I
  faIndustry as IconDefinition,
  faInfoCircle as IconDefinition,
  // K
  faKeyboard as IconDefinition,
  // L
  faListCheck as IconDefinition,
  // M
  faMale as IconDefinition,
  faMinus as IconDefinition,
  // P
  faPlug as IconDefinition,
  faPlus as IconDefinition,
  faPowerOff as IconDefinition,
  // Q
  faQuestion as IconDefinition,
  // R
  faRuler as IconDefinition,
  faRulerCombined as IconDefinition,
  faRulerHorizontal as IconDefinition,
  faRulerVertical as IconDefinition,
  faRunning as IconDefinition,
  // S
  faSave as IconDefinition,
  faShapes as IconDefinition,
  faSignOutAlt as IconDefinition,
  faSortNumericDown as IconDefinition,
  faStopwatch as IconDefinition,
  faStream as IconDefinition,
  faSubscript as IconDefinition,
  // T
  faToggleOn as IconDefinition,
  faTrafficLight as IconDefinition,
  // U
  faUser as IconDefinition,
);

const ii = {
  'ii-dashboard': 'dashboard',
  'ii-event': 'calendar',
  'ii-machine-state': 'play-circle',
  'ii-sensor': 'microchip',
  'ii-stream': 'microchip',
  'ii-variable': 'subscript',
  'ii-shift': 'clock-o',
  'ii-stopwatch': 'stopwatch',
  'ii-media-icon': 'media',
  'ii-timeline-icon': 'timeline',
  'ii-progressBar-icon': 'progressBar',
};

const inFontAwesomeIcon = new Set([
  'arrow-left', 'arrow-right',
  'bars-staggered',
  'chart-line', 'chart-pie', 'check-double', 'circle-notch', 'clock', 'comment-alt', 'compress', 'concierge-bell',
  'equals', 'exclamation-circle', 'expand',
  'industry', 'info-circle',
  'keyboard',
  'list-check',
  'male', 'message',
  'question',
  'ruler', 'ruler-combined', 'ruler-horizontal', 'ruler-vertical', 'running',
  'save', 'shapes', 'sign-out-alt', 'sort-numeric-down', 'stopwatch', 'subscript',
  'toggle-on', 'traffic-light',
]);

const inSvgIcon = new Set([
  'button',
  'carousel',
  'dashboard',
  'media',
  'microsoft',
  'timeline',
  'progressBar',
  'santaRun',
  'santaStop',
  'xmark',
]);

const FontAwesomeComponent = ({ icon = '', ...props }) => {
  // check if the icon is a predefined one
  const iconToUse = icon.startsWith('ii-') ? ii[icon] : icon;

  if (!icon) {
    return null;
  }

  if (inSvgIcon.has(iconToUse)) {
    return <SvgIcon icon={iconToUse} {...props} />;
  }

  if (inFontAwesomeIcon.has(iconToUse)) {
    return (
      <span>
        <FontAwesomeIcon
          icon={iconToUse}
          style={{ color: props.color, ...props.style }}
          {...props}
        />
      </span>
    );
  }
  return (
    <FontAwesome
      name={iconToUse}
      style={{ color: props.color, ...props.style }}
      {...props}
    />
  );
};

FontAwesomeComponent.propTypes = {
  icon: PropTypes.string.isRequired,
  color: PropTypes.string,
  style: PropTypes.shape({}),
};

FontAwesomeComponent.defaultProps = {
  style: {},
};

export default FontAwesomeComponent;
